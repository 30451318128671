import { observer } from 'mobx-react-lite';
import React, { Fragment } from 'react';
import { Header } from 'semantic-ui-react';
import { useStore } from '../../app/stores/store';
import DisbursementListItem from './DisbursementListItem';

export default observer(function DisbursementList() {
    const { disbursementStore } = useStore();
    const { groupedDisbursements } = disbursementStore;

    return (
        <>
            {groupedDisbursements.map(([group, disbursements]) => (
                <Fragment key={group}>
                    <Header sub color='teal'>
                        {group}
                    </Header>
                    {disbursements.map(disbursement => (
                        <DisbursementListItem key={disbursement.id} disbursement={disbursement} />
                    ))}
                </Fragment>
            ))}
        </>

    )
})