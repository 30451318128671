import React from "react";
import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Button, Header } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import * as Yup from 'yup';
import ValidationError from "../errors/ValidationErrors";
import SirTextInput from "../../app/common/SirTextInput";
import SirSelectInput from "../../app/common/SirSelectInput";
import { Role } from "../../app/models/role";

interface Res {
    text: string;
    value: string;
}

export default observer(function RegisterForm() {
    const { userStore } = useStore();
    const [getRoles, setRole] = React.useState<Role[]>([]);

    React.useEffect(() => {
        userStore.loadRoles().then((res) => setRole(res ? res : []))
            .catch((error) => console.log(error));
    }, []);

    let results: Res[] = [];

    const mapRolesToResult = () => {
        getRoles.forEach(role => {
            let r = {} as Res;
            r.text = role.name;
            r.value = role.name;
            results.push(r);
        })
    };
    mapRolesToResult();

    return (
        <Formik
            initialValues={{ name: '', username: '', email: '', password: '', confirmPassword: '', error: null }}
            onSubmit={(values, { setErrors }) =>
                userStore.register(values).catch(error => setErrors({ error: error }))}
            validationSchema={Yup.object({
                name: Yup.string().required('Full name required'),
                username: Yup.string().required('Username required'),
                email: Yup.string().required('Email required'),
                selectedrole: Yup.string().required('User must belong to a role'),
                password: Yup.string().required('Password must not be empty'),
                confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], "Passwords don't match!").required('Password must not be empty')
            })}
        >
            {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
                <Form className='ui form error' onSubmit={handleSubmit} autoComplete='off'>
                    <Header as='h2' content='Register new user' color="teal" textAlign="center" />
                    <SirTextInput placeholder="Full Name" name='name' />
                    <SirTextInput placeholder="Username" name='username' />
                    <SirTextInput placeholder="Email" name='email' />
                    <SirSelectInput options={results} placeholder='Role' name='selectedrole' label='Role' />
                    <SirTextInput placeholder="Password" name='password' type='password' />
                    <SirTextInput placeholder="Confirm Password" name='confirmPassword' type='password' />
                    <ErrorMessage name='error' render={() =>
                        <ValidationError errors={errors.error} />} />
                    <Button
                        disabled={!isValid || !dirty || isSubmitting}
                        loading={isSubmitting}
                        positive content='Register'
                        type="submit" fluid
                    />
                </Form>
            )}

        </Formik>
    )
})
