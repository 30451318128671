import React from 'react'
import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";
import SiteInspectionReportDashboard from "../../components/dashboard/SiteInspectionReportDashboard";
import SiteInspectionReportDetails from "../../components/detailedReports/SiteInspectionReportDetails";
import MainDashboard from "../../components/dashboard/MainDashboard";
import SiteInspectionReportForm from "../../components/CardForm";
import ReadonlyCardForm from "../../components/ReadonlyReport/ReadonlyCardForm";
import NotFound from "../../components/errors/NotFound";
import ServerError from "../../components/errors/ServerError";
import TestErrors from "../../components/errors/TestError";
import ProfilePage from "../../components/profiles/ProfilePage";
import App from "../../components/layout/App";
import RequireAuth from "./RequireAuth";
import AdminPage from '../../components/adminPanel/AdminPage';
import Disbursement from '../../components/Disbursement/Disbursement';
import DisbursementsDashboard from '../../components/Disbursement/DisbursementsDashboard';
import DisbursementDetails from '../../components/Disbursement/DisbursementDetails';
import RefDataPage from '../../components/adminPanel/RefDataPage';
import ResetPasswordForm from '../../components/users/ResetPasswordForm';

export const routes: RouteObject[] = [
    {
        path: '/',
        element: <App />,
        children: [
            {
                element: <RequireAuth roles={["SITEENG", "ADMIN", "KFW", "USIFE", "USIFV"]} />, children: [
                    { path: 'dashboard', element: < MainDashboard /> },
                    { path: 'reports', element: <SiteInspectionReportDashboard /> },
                    { path: 'reports/:id', element: <SiteInspectionReportDetails /> },
                    { path: 'disbursements', element: <DisbursementsDashboard /> },
                    { path: 'disbursements/:id', element: <DisbursementDetails /> },
                    { path: 'updatedisbursements/:id', element: <Disbursement /> },
                    { path: 'manage/:id', element: <SiteInspectionReportForm key='manage' /> },
                    { path: 'profiles/:username', element: <ProfilePage /> },
                    { path: 'errors', element: <TestErrors /> }
                ]
            },
            {
                element: <RequireAuth roles={["ADMIN", "KFW"]} />, children: [
                    { path: 'progressstatus/:id', element: <ReadonlyCardForm key='progressstatus' /> }
                ]
            }, {
                element: <RequireAuth roles={["SITEENG", "ADMIN"]} />, children: [
                    { path: 'createReport', element: <SiteInspectionReportForm key='createReport' /> },
                    { path: 'createDisbursement', element: <Disbursement key='createDisbursement' /> },
                ]
            },
            {
                element: <RequireAuth roles={["ADMIN"]} />, children: [
                    { path: 'adminpanel/:username', element: <AdminPage /> },
                    { path: 'refdata', element: <RefDataPage /> }
                ]
            },
            { path: 'reset-password', element: <ResetPasswordForm key='resetPassword'/> },
            { path: 'not-found', element: <NotFound /> },
            { path: 'server-error', element: <ServerError /> },
            { path: '*', element: <Navigate replace to='/not-found' /> },
        ]
    }
]

export const router = createBrowserRouter(routes);