import React from 'react';
import { Button, Placeholder, Card, Grid, Icon } from 'semantic-ui-react';

export default function DisbursementListItemPlaceholder() {
    return (

        <Card fluid>
            <Card.Content>
                <Grid columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column>
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Grid.Column>
                        <Grid.Column>
                            <Button
                                disabled
                                icon
                                color='yellow'
                                size='mini'
                            >
                                <Icon name='eye' />
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Card.Content >
        </Card >
    );
};