import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { FormField, Grid, Select } from 'semantic-ui-react';
import { Oblast } from '../../app/models/oblast';
import { useStore } from '../../app/stores/store';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormControl, TextField } from '@mui/material';
import dayjs from 'dayjs';

interface Res {
    text: string;
    value: number;
}

export default observer(function DisbursementsFilters() {
    const { disbursementStore: { predicate, setPredicate } } = useStore();
    const [getOblast, setOblast] = React.useState<Oblast[]>([]);
    const { refDataStore } = useStore();

    React.useEffect(() => {
        refDataStore.loadOblasts().then((res) => setOblast(res ? res : []))
            .catch((error) => console.log(error));
    }, []);

    let results: Res[] = [];

    const mapOblastToResult = () => {
        getOblast.forEach(oblast => {
            let r = {} as Res;
            r.text = oblast.name;
            r.value = oblast.id;
            results.push(r);
        })
    }
    mapOblastToResult();

    const [monthStart, setMonthStart] = useState(new Date(new Date().setFullYear(new Date().getFullYear() - 1)));
    const [monthEnd, setMonthEnd] = useState(new Date());

    const handleStartDateChange = (date) => {
        setMonthStart(date);
        if (date) {
            const year = date.year();
            const month = date.month();
            const firstDayOfMonth = dayjs(`${year}-${month + 1}-01`);
            setMonthStart(firstDayOfMonth.toDate());
            setPredicate('monthStart', new Date(date).toISOString());
        }
    };

    const handleEndDateChange = (date) => {
        setMonthEnd(date);
        if (date) {
            const year = date.year();
            const month = date.month();
            const lastDayOfMonth = dayjs(`${year}-${month + 1}-${dayjs(`${year}-${month + 1}`).daysInMonth()}`);
            setMonthEnd(lastDayOfMonth.toDate());
            setPredicate('monthEnd', lastDayOfMonth.toISOString());
        }
    };

    return (
        <>
            <Grid columns={1} key='FilterGrid' style={{ position: "fixed", top: "110px", width: "500px" }}>
                <label style={{ color: "#2364AA", fontSize: "15px" }}>Filter by Oblast</label>
                <Grid.Row columns={1}>
                    <Grid.Column  >
                        <FormControl fullWidth>
                            <Select
                                style={{ backgroundColor: "#EBF5DF", boxShadow: "2px 2px 2px #888888" }}
                                options={results}
                                clearable
                                search
                                //allowAdditionsMessage
                                onChange={(e, data) => {
                                    setPredicate('oblastId', data.value as number);
                                }}
                            />
                        </FormControl>
                    </Grid.Column>
                </Grid.Row>
                <label style={{ color: "#2364AA", fontSize: "15px" }}>Filter by Month</label>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <FormField>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    disableFuture
                                    openTo="year"
                                    views={['year', 'month']}
                                    label="Start Month"
                                    value={monthStart}
                                    onChange={handleStartDateChange}
                                    maxDate={monthEnd} // Ensure Start Month cannot be after End Month
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{ backgroundColor: "#EBF5DF", boxShadow: "2px 2px 2px #888888" }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </FormField>
                    </Grid.Column>
                    <Grid.Column>
                        <FormField>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    openTo="year"
                                    views={['year', 'month']}
                                    label="End Month"
                                    value={monthEnd}
                                    onChange={handleEndDateChange}
                                    minDate={monthStart} // Ensure End Month cannot be before Start Month
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{ backgroundColor: "#EBF5DF", boxShadow: "2px 2px 2px #888888" }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </FormField>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    )
});