import { observer } from 'mobx-react-lite';
import React, { Fragment } from 'react';
import { Header } from 'semantic-ui-react';
import { useStore } from '../../app/stores/store';
import ReportListItem from './ReportListItem';

export default observer(function SiteInspectionReportList() {
    const { siteInspectionReportStore } = useStore();
    const { groupedReports } = siteInspectionReportStore;

    return (
        <>
            {groupedReports.map(([group, reports]) => (
                <Fragment key={group}>
                    <Header sub color='teal'>
                        {group}
                    </Header>
                    {reports.map(report => (
                        <ReportListItem key={report.id} report={report} />
                    ))}
                </Fragment>
            ))}
        </>

    )
})