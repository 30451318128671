import React from "react";
import { Query } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import { Chart, ReactGoogleChartEvent } from "react-google-charts";
import { observer } from "mobx-react-lite";
import LoadingComponent from "../../../layout/LoadingComponent";

export default observer(function SubProjectsDashboard({ oblast, onSelect }: { oblast: string, onSelect: (selectedSpNameSpCode: string) => void }) {
    const query: Query = {
        dimensions: [
            'VwSiteInspectionReport.spnamespcode',
            'VwSiteInspectionReport.spcode',
        ],
        order: {
            'VwSiteInspectionReport.spnamespcode': 'asc',
        },
        measures: ['VwSiteInspectionReport.count'],
        timeDimensions: [{
            dimension: 'VwSiteInspectionReport.createdon',
            dateRange: ['2006-01-01', '2026-12-31']
        }],
        filters: [
            {
                dimension: 'VwSiteInspectionReport.oblastname',
                operator: 'contains',
                values: [oblast ? oblast : '']
            }
        ]
    };

    // Let's specify a few options like pagination for the table
    const options = {
        showRowNumber: true,
        page: "enable",
        pageSize: 20
    };

    const labels = ["SP - SubProjects", "No."];

    const { resultSet, isLoading, error } = useCubeQuery(query);

    if (isLoading || !resultSet) {
        return <LoadingComponent content='Loading...' />;
    }

    if (error) {
        return <div>{error.toString()}</div>;
    }

    const rows = resultSet?.tablePivot()
        .map((row) => [row["VwSiteInspectionReport.spnamespcode"], Number(row["VwSiteInspectionReport.count"])]);
    // Second, let's convert it into Google Charts data table.
    // Note that we're passing an array of column names as the first row
    const data = [labels, ...rows!];

    function getEvents(onSelect: ((selected: string) => void) | undefined, data: any[]): ReactGoogleChartEvent[] {
        return [
            {
                eventName: "select",
                callback({ chartWrapper }) {
                    const chart = chartWrapper.getChart();
                    const selection = chart.getSelection().map((_) => data[_.row + 1][0] as string)[0];
                    const selection1 = selection ? selection : '';
                    
                    onSelect?.(selection1);
                }
            }
        ];
    }

    const events = getEvents(onSelect, data);

    return (
        <Chart
            chartType="Table"
            width="100%"
            height={500}
            data={data}
            options={options}
            chartEvents={events}
        />
    );
});
