import React from "react";
import { Card, Header, Segment } from "semantic-ui-react";
import { DisbursementFormValues } from "../../app/models/disbursement"
import { Formik, Form } from "formik";
import { observer } from "mobx-react-lite";
import DisbursementInformation from "./DisbursementInformation";

const DisbursementReadonlyView = ({ formValues }: { formValues: DisbursementFormValues }) => {
    return (
        <Segment clearing style={{ height: "1790px", backgroundColor: "transparent", border: "0px " }}>
            <Header content='Disbursement Details' sub color='teal' />
            <Formik
                enableReinitialize
                initialValues={formValues}
                onSubmit={() => { }}>
                {formikProps => {
                    // Destructure the Formik props you want to pass down
                    return (
                        <Form className='ui form' autoComplete='off'>
                            <Card fluid key='disbursementCard'  >
                                <Card.Content style={{ color: 'teal' }}>
                                    <h1>View Disbursement</h1>
                                </Card.Content>
                                <Card.Content extra key="GeneralInfo">
                                    <DisbursementInformation
                                        disbursement={formValues}
                                        formikProps={formikProps}
                                        readOnly />
                                </Card.Content>
                            </Card>
                        </Form>
                    )
                }}
            </Formik>
        </Segment>
    );
};

export default observer(DisbursementReadonlyView);


