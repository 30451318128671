import { Formik, Form } from 'formik';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import * as Yup from 'yup';
import { Button, Divider } from 'semantic-ui-react';
import SirTextInput from '../../app/common/SirTextInput';
import ReCAPTCHA from "react-google-recaptcha";

export default observer(function ForgotPasswordForm() {
    const { userStore } = useStore();

    return (
        <Formik
            initialValues={{ email: '', captcharesponse: '', error: null }}
            onSubmit={(values, { setErrors }) => {
                if (!values.captcharesponse) {
                    setErrors({ captcharesponse: 'Please verify you are a human' });
                    return;
                }
                userStore.forgotPassword(values).catch(error =>
                    setErrors({ error: 'Error sending email' }))
            }}
            validationSchema={Yup.object({
                email: Yup.string().required('Email required').email('Invalid email format')
            })}
        >
            {({ isSubmitting, setFieldValue }) => (
                <Form className='ui form' autoComplete="off">
                    <SirTextInput placeholder="Email" name='email' />
                    <Divider />  {/* Divider to create some space */}
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={(value) => {
                            setFieldValue('captcharesponse', value);
                        }}
                    />
                    <Button loading={isSubmitting} positive content='Send email' type='submit' fluid />
                </Form>
            )}
        </Formik>
    );
});
