import { observer } from 'mobx-react-lite';
import React from 'react';
import { Tab } from 'semantic-ui-react';
import { Profile } from '../../app/models/profile';
import ProfilePassword from '../profiles/ProfilePassword';
import RegisterForm from './RegisterForm';
import ViewAllUsers from './ViewAllUsers';

interface Props {
    profile: Profile;
}

export default observer(function AdminContent({ profile }: Props) {
    const panes = [
        {
            menuItem: 'View Users',
            render: () => <ViewAllUsers />
        },
        {
            menuItem: 'Register new user',
            render: () => <RegisterForm />
        },
        {
            menuItem: 'Update password',
            render: () => <ProfilePassword />
        },
    ];

    return (
        <Tab
            menu={{ fluid: true, vertical: true, tabular: true }}
            menuPosition='left'
            panes={panes}
            grid={{ paneWidth: 12, tabWidth: 4 }}
        />
    )
})
