import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Container, Menu, Image, Dropdown } from 'semantic-ui-react';
import { useStore } from '../../app/stores/store';
import './NavStyle.css';

export default observer(function NavBar() {
    const { userStore: { user, logout } } = useStore();
    let username = '';
    let roles: string[] = [];
    if (user) {
        username = user.username;
        roles = user.roles ? user.roles : [];
    }

    return (
        <Menu inverted fixed='top'>
            <Container style={{ border: 'none' }}>
                <Menu.Item as={NavLink} to='/' header className='header-navbar'>
                    {/*<img src="/assets/m4h-logo-web.png" alt='logo' />*/}
                    Home
                </Menu.Item>
                <Menu.Item as={NavLink} to='/dashboard' name='Dashboard' className='header-navbar' />
                <Dropdown text='Reports' pointing className='link item header-navbar'>
                    <Dropdown.Menu>
                        {roles.some((userRoles) => userRoles === 'SITEENG' || userRoles === 'ADMINISTRATOR') && (
                            <Dropdown.Item as={NavLink} to='/createReport' name='Add Report' text='Add Report' className='header-navbar' key='createReport' />
                        )}
                        <Dropdown.Item as={NavLink} to='/reports' name='View Reports' text='View Reports' className='header-navbar' key='reportsView' />
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown text='Disbursements' pointing className='link item header-navbar'>
                    <Dropdown.Menu>
                        {roles.some((userRoles) => userRoles === 'SITEENG' || userRoles === 'ADMINISTRATOR') && (
                            <Dropdown.Item as={NavLink} to='/createDisbursement' name='Add Disbursement' text='Add Disbursement' className='header-navbar' key='createDisbursement' />
                        )}
                        <Dropdown.Item as={NavLink} to='/disbursements' name='View Disbursements' text='View Disbursements' className='header-navbar' key='disbursementView' />
                    </Dropdown.Menu>
                </Dropdown>
                {roles.some((userRoles) => userRoles === 'ADMIN') && (
                    <Dropdown text='Administration Panel' pointing className='link item header-navbar'>
                        <Dropdown.Menu>
                            {/*<Dropdown.Item as={NavLink} to='/createDisbursement' name='Add Disbursement' text='Add Disbursement' className='header-navbar' />*/}
                            <Dropdown.Item as={NavLink} to={`/adminpanel/${username}`} name='Users' text='Users' className='header-navbar' key='adminPanel' />
                            <Dropdown.Item as={NavLink} to='/refdata' name='Reference Data' text='Reference Data' className='header-navbar' key='refData' />
                        </Dropdown.Menu>
                    </Dropdown>
                )}
                <Menu.Item position='right'>
                    <Image src={'/assets/user.png'} avatar spaced='right' title='User' />
                    <Dropdown pointing='top left' text={username}>
                        <Dropdown.Menu>
                            <Dropdown.Item as={Link} to={`/profiles/${username}`} text='My Profile' icon='user' key='profile' />
                            <Dropdown.Item onClick={logout} text='Logout' icon='power' key='logout' />
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Item>
            </Container>
        </Menu>
    );
});
