import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material/';
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { FormikProps } from 'formik';
import { SiteInspectionReportFormValues } from '../../app/models/siteinspectionreport';
import SirTextArea from '../../app/common/SirTextArea';
import SirTextInput from '../../app/common/SirTextInput';
import SirImageUpload from '../../app/common/SirImageUpload';

interface ObservanceDialogBoxProps {
    formikProps: FormikProps<SiteInspectionReportFormValues>;
    observanceIndex?: number; // Index of the observance being edited, if applicable
    handleClose: () => void;
    open: boolean;
}

const ObservanceDialogBox: React.FC<ObservanceDialogBoxProps> = ({ formikProps, observanceIndex, handleClose, open }) => {

    const isCreatingNew = observanceIndex === undefined;
    const fieldNamePrefix = isCreatingNew ? 'newObservanceOngoingActivity' : `observanceOngoingActivities[${observanceIndex}]`;

    const useStyles = makeStyles((theme) => ({
        root: {
            "& .MuiFormControl-root": {
                width: "90%",
                margin: theme.spacing(1),
            },
        },
    }));
    const classes = useStyles();

    const handleSave = () => {
        if (isCreatingNew) {
            const values: any = formikProps.values;
            console.log('newObservanceOngoingActivity', values.newObservanceOngoingActivity);
            // Logic to add a new activity to the ongoingActivities array
            formikProps.setFieldValue('observanceOngoingActivities', [...formikProps.values.observanceOngoingActivities!, values.newObservanceOngoingActivity]);
        } else {
            let updatedObservanceOngoingActivities = [...formikProps.values.observanceOngoingActivities!];
            updatedObservanceOngoingActivities[observanceIndex] = formikProps.values.observanceOngoingActivities![observanceIndex];
            formikProps.setFieldValue('observanceOngoingActivities', updatedObservanceOngoingActivities);
        }
        handleClose(); // Close the dialog box
    };


    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {isCreatingNew ? "Create new observance" : "Update observance"}
                </DialogTitle>
                <DialogContent>
                    <form className={classes.root}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <SirTextArea
                                    name={`${fieldNamePrefix}.observance`}
                                    placeholder="Observance"
                                    minRows={2}
                                    maxRows={4}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SirTextInput
                                    name={`${fieldNamePrefix}.photoName`}
                                    placeholder="Photo Name"
                                    label="Name of Photo"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SirImageUpload name={`${fieldNamePrefix}.photo`} />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" variant="outlined">Cancel</Button>
                    <Button onClick={() => handleSave()} color="primary" variant="contained">
                        {isCreatingNew ? 'Create' : 'Update'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ObservanceDialogBox;