import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from '../../app/stores/store';
import CardFormView from '../CardFormView';
import LoadingComponent from '../layout/LoadingComponent';

export default observer(function SiteInspectionReportDetails() {
    const { siteInspectionReportStore } = useStore();
    const { selectedSiteInspectionReport: report, loadSiteInspectionReport, loadingInitial, clearSelectedSiteInspectionReport } = siteInspectionReportStore;
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (id) loadSiteInspectionReport(id);
        return () => clearSelectedSiteInspectionReport();
    }, [id, loadSiteInspectionReport, clearSelectedSiteInspectionReport]);

    if (loadingInitial || !report) return <LoadingComponent />;

    return (
        <CardFormView formValues = {report } key={id } />
    )
})