import { useState, useRef } from 'react';
import React from 'react';
import { Button, Grid } from '@material-ui/core/';
import QualityAndProgresDialogBox from './QualityAndProgresDialogBox'
import { v4 as uuid } from 'uuid';
import { toJS } from 'mobx'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { QualityAndProgress } from '../../app/models/qualityandprogress';
import { SiteInspectionReportFormValues } from '../../app/models/siteinspectionreport';
import { makeStyles } from "@material-ui/core/styles";
import { FormikProps } from 'formik';
import ConfirmationDialog from '../UI/ConfirmationDialog';

const useStyles = makeStyles({
    root: {
        background: "#278DAD",
        borderRadius: 3,
        border: 0,
        color: "white",
        height: 40,
        padding: "0 30px",
        boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
        '&:hover': {
            backgroundColor: '#D7C333',
            color: '#3c52b2'
        }
    },
    label: {
        textTransform: "capitalize"
    }
});

const arr: QualityAndProgress[] = [];

const QualityProgress = ({
    report,
    readOnly = false,
    formikProps = {} as FormikProps<SiteInspectionReportFormValues> }: {
        report: SiteInspectionReportFormValues,
        readOnly: boolean,
        formikProps: FormikProps<SiteInspectionReportFormValues>
    }) => {

    const classes = useStyles();
    const [editingQualityIndex, setEditingQualityIndex] = React.useState<number | undefined>(undefined);

    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
    const [qualityToRemove, setQualityToRemove] = React.useState(null);

    const onRemove = (index) => {
        setQualityToRemove(index);
        setIsConfirmDialogOpen(true);
    };

    const handleConfirmDelete = () => {
        const updatedQualityAndProgress = formikProps.values.qualityAndProgresses!.filter((_, i) => i !== qualityToRemove);
        formikProps.setFieldValue('qualityAndProgresses', updatedQualityAndProgress);

        setIsConfirmDialogOpen(false);
        setQualityToRemove(null);
    };


    const checkObs = () => {
        if (report.qualityAndProgresses !== undefined && report.qualityAndProgresses !== null) {
            report.qualityAndProgresses = toJS(report.qualityAndProgresses);

        } else {
            report.qualityAndProgresses = arr;
        }
    }
    checkObs();

    let nextId = uuid()

    const initialValues : QualityAndProgress = {
        id: nextId,
        issueTypeId: 0,
        issueTypeText: "",
        comments: "",
        remarks: "",
        impact: true,
        photoName: "",
        photo: null,
        siteInspectionReportId: report.id
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const readOnlyColumnDefs = [
        { headerName: "ID", field: "id", hide: true },
        { headerName: "Issue Type Id", field: "issueTypeId", hide: true },
        { headerName: "Issue Type", field: "issueTypeText", flex: 2, resizable: true },
        { headerName: "Comments", field: "comments", flex: 5, resizable: true },
        { headerName: "Remarks", field: "remarks", flex: 5, resizable: true },
        {
            headerName: "Impact", field: "impact", flex: 2, resizable: true,
            cellRenderer: (params) => params.value ? "Yes" : "No"
        },
        { headerName: "Photo", field: "photo", hide: true },
    ];

    const editableColumnDefs = [
        { headerName: "ID", field: "id", hide: true },
        { headerName: "Issue Type Id", field: "issueTypeId", hide: true },
        { headerName: "Issue Type", field: "issueTypeText", flex: 2, resizable: true },
        { headerName: "Comments", field: "comments", flex: 5, resizable: true },
        { headerName: "Remarks", field: "remarks", flex: 5, resizable: true },
        {
            headerName: "Impact", field: "impact", flex: 2, resizable: true,
            cellRenderer: (params) => params.value ? "Yes" : "No"
        },
        { headerName: "Photo", field: "photo", hide: true },
        {
            headerName: "Actions",
            field: "id",
            flex: 3,
            cellRenderer: (params) =>
                <div>
                    <Button color="primary" onClick={() => onEdit(params.rowIndex)} ><ModeEditOutlineOutlinedIcon /></Button>
                    <Button color="secondary" onClick={() => onRemove(params.rowIndex)}><DeleteOutlinedIcon /></Button>
                </div>
        },
    ]


    const defaultColDef = {
        sortable: true,
        flex: 1, filter: true,
    }

    const gridRef = useRef<AgGridReact>(null);

    const onEdit = (index: number) => {
        setEditingQualityIndex(index);
        handleClickOpen();
    }

    const handleCreateQualityAndProgress = () => {
        formikProps.setFieldValue('newQualityAndProgress', initialValues);
        setEditingQualityIndex(undefined);
        handleClickOpen();
    }


    return (
        <div className="App">
            {readOnly ? null : (
                <Grid container>
                    <Grid>
                        <Button
                            variant="contained"
                            onClick={() => { handleCreateQualityAndProgress(); }}
                            classes={{
                                root: classes.root,
                                label: classes.label
                            }}
                        >
                            Add New Quality and Progress
                        </Button>
                    </Grid>
                </Grid>
            )}
            <div className="ag-theme-alpine" style={{ height: '300px', width: "100%" }}>
                <AgGridReact
                    ref={gridRef}
                    rowData={formikProps.values.qualityAndProgresses}
                    columnDefs={readOnly ? readOnlyColumnDefs : editableColumnDefs}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    pagination={true}
                    rowSelection='multiple'
                    paginationPageSize={4}

                />
            </div>
            <QualityAndProgresDialogBox
                formikProps={formikProps}
                qualityAndProgressIndex={editingQualityIndex}
                open={open}
                handleClose={handleClose}
            />
            <ConfirmationDialog
                open={isConfirmDialogOpen}
                onClose={() => setIsConfirmDialogOpen(false)}
                onConfirm={handleConfirmDelete}
            />
        </div>
    );
};

export default QualityProgress;
