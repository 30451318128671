import * as React from 'react';
import { FormField, Grid } from "semantic-ui-react";
import SirDateInput from '../../app/common/SirDateInput';
import SirSelectInput from '../../app/common/SirSelectInput';
import SirTextInput from '../../app/common/SirTextInput';
import { DisbursementFormValues } from '../../app/models/disbursement';
import { Oblast } from '../../app/models/oblast';
import { SpName } from '../../app/models/spname';
import { useStore } from '../../app/stores/store';
import AppMap from '../Map/AppMap';
import { FormikProps } from 'formik';

interface Res {
    text: string;
    value: number;
}

const DisbursementInformation = ({
    disbursement,
    readOnly = false,
    formikProps = {} as FormikProps<DisbursementFormValues>
}: {
    disbursement: DisbursementFormValues,
    readOnly: boolean,
    formikProps: FormikProps<DisbursementFormValues>
}) => {
    const [getOblast, setOblast] = React.useState<Oblast[]>([]);
    const [getSpName, setSpName] = React.useState<SpName[]>([]);
    const [getSpCode, setSpCode] = React.useState<SpName>();
    const { refDataStore } = useStore();

    React.useEffect(() => {
        refDataStore.loadOblasts().then((res) => setOblast(res ? res : []))
            .catch((error) => console.log(error));
    }, [refDataStore]);

    let results: Res[] = [];
    let spNames: Res[] = [];
    const [data, setData] = React.useState({
        coordinateX: disbursement.coordinateX,
        coordinateY: disbursement.coordinateY,
        mapReadonly: readOnly
    });

    const getSpFromOblast = (oblastId: number) => {
        refDataStore.loadSpNames(oblastId).then((res) => {
            if (res) {
                setSpName(res.data);
                mapSpNameToResults();
            }
        });
    }

    const mapOblastToResult = () => {
        getOblast.forEach(oblast => {
            let r = {} as Res;
            r.text = oblast.name;
            r.value = oblast.id;
            results.push(r);
        })
    }

    const mapSpNameToResults = () => {
        getSpName.forEach(spName => {
            let r = {} as Res;
            r.text = spName.name;
            r.value = spName.id;
            spNames.push(r);
        })
        return spNames;
    }
    React.useEffect(() => {
        if (disbursement) {
            if (disbursement.oblastId) {
                getSpFromOblast(disbursement.oblastId);
            }
            if (disbursement.spNameId) {
                mapSpToSpCode(disbursement.spNameId);
            }
        }
    }, [disbursement])

    const mapSpToSpCode = async (spId: number) => {
        try {
            refDataStore.loadSpNameDetails(spId).then((res) => {
                if (res) {
                    setSpCode(res.data);
                    // Update the data state
                    setData({
                        ...data,
                        coordinateY: res.data.coordinateY,
                        coordinateX: res.data.coordinateX
                    });
                    formikProps.setFieldValue('coordinateY', res.data.coordinateY);
                    formikProps.setFieldValue('coordinateX', res.data.coordinateX);
                }
            });
        }
        catch (error) {
            console.error('Error loading SP Name details:', error);
        }
    }

    const handleCoordinateChanges = () => {
        formikProps.setFieldValue('coordinateY', data.coordinateY);
        formikProps.setFieldValue('coordinateX', data.coordinateX);
    }
    console.log("disbursement", disbursement);

    mapOblastToResult();
    mapSpNameToResults();
    mapSpToSpCode(0);

    return (
        <>
            <Grid columns={3} stackable>
                <Grid.Row>
                    <Grid.Column>
                        <FormField key='oblastIdFormField'>
                            <SirSelectInput options={results} placeholder='Oblast' name='oblastId' onChange={getSpFromOblast} label='Oblast' disabled={readOnly} />
                        </FormField>
                    </Grid.Column>
                    <Grid.Column>
                        <FormField key='spNameIdFormField'>
                            <SirSelectInput options={spNames} placeholder='SP Name' name='spNameId' onChange={mapSpToSpCode} label='SP Name' disabled={readOnly} />
                        </FormField>
                    </Grid.Column>
                    <Grid.Column>
                        <FormField key='spCodeFormField'>
                            <SirTextInput name='spCode' placeholder='SP Code' value={getSpCode ? getSpCode.spCode : ""} disabled={readOnly} label='SP Code' />
                        </FormField>
                    </Grid.Column>
                    <Grid.Column>
                        <FormField key='contractorFormField'>
                            <SirTextInput name='contractor' placeholder='Contractor' label='Contractor' disabled={readOnly} />
                        </FormField>
                    </Grid.Column>
                    <Grid.Column>
                        <FormField key='contractSigningDateFormField'>
                            <label>Contract Signing Date</label>
                            <SirDateInput name='contractSigningDate'
                                nextYearAriaLabel="Next Year"
                                placeholderText='Contract Signing Date'
                                showYearDropdown
                                disabled={readOnly}
                            />

                        </FormField>
                    </Grid.Column>
                    <Grid.Column>
                        <FormField key='contractEndingDateFormField'>
                            <label>Contract Ending Date</label>
                            <SirDateInput name='contractEndingDate'
                                nextYearAriaLabel="Next Year"
                                placeholderText='Contract Ending Date'
                                showYearDropdown
                                disabled={readOnly}
                            />
                        </FormField>
                    </Grid.Column>
                    <Grid.Column>
                        <FormField>
                            <SirTextInput type="number" name='value' placeholder='Contract Price' label='Contract Price' disabled={readOnly} />
                        </FormField>
                    </Grid.Column>
                    <Grid.Column>
                        <FormField>
                            <label>Month of Certificate</label>
                            <SirDateInput name='month'
                                dateFormat="MMMM yyyy"
                                showMonthYearPicker
                                nextYearAriaLabel="Next Year"
                                placeholderText='Month of Certificate'
                                showYearDropdown
                                disabled={readOnly}
                            />
                        </FormField>
                    </Grid.Column>
                    <Grid.Column key='amountOfPaymentFormField'>
                        <FormField>
                            <SirTextInput type="number" name='amountOfPayment' placeholder='Amount of Payment' label='Amount of Payment' disabled={readOnly} />
                        </FormField>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <AppMap data={data} handleCoordinateChanges={handleCoordinateChanges} />
        </>
    );
}

export default DisbursementInformation;