import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from '../../app/stores/store';
import LoadingComponent from '../layout/LoadingComponent';
import DisbursementReadonlyView from './DisbursementReadonlyView';

export default observer(function DisbursementDetails() {
    const { disbursementStore } = useStore();
    const { selectedDisbursement: disbursement, loadDisbursement, loadingInitial, clearSelectedDisbursement } = disbursementStore;
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (id) loadDisbursement(id);
        return () => clearSelectedDisbursement();
    }, [id, loadDisbursement, clearSelectedDisbursement]);

    if (loadingInitial || !disbursement) return <LoadingComponent />;

    return (
        <DisbursementReadonlyView formValues={disbursement} key={id} />
    )
})