import { observer } from 'mobx-react-lite';
import React from 'react';
import { Tab } from 'semantic-ui-react';
import SubProjects from './SubProjects';

export default observer(function RefDataContent() {
    const panes = [
        {
            menuItem: 'Sub Projects',
            render: () => <SubProjects />
        }
    ];

    return (
        <Tab
            menu={{ fluid: true, vertical: true, tabular: true }}
            menuPosition='left'
            panes={panes}
            grid={{ paneWidth: 12, tabWidth: 4 }}
        />
    )
})
