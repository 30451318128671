import * as React from 'react';
import { Accordion, Card, Header, Icon, Segment, Button } from "semantic-ui-react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useStore } from '../app/stores/store';
import { observer } from 'mobx-react-lite';
import { SiteInspectionReportFormValues } from '../app/models/siteinspectionreport';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';
import GeneralInformation from './GeneralInformation';
import ObservanceOnOngoingActivities from './ObservanceOnOngoingActivities/ObservanceOnOngoingActivities';
import QualityProgress from './QualityAndProgress/QualityAndProgress';
import { Formik, Form } from 'formik';
import LoadingComponent from './layout/LoadingComponent';
import Progress from './Progress';
import HsObservation from './HealthAndSafetyForm/HsObservation';
import OngoingActivities from './OngoingActivities/OngoingActivities';
import { Percent } from '@mui/icons-material';

export default observer(function CardForm() {

    const [activeIndex, setActiveIndex] = useState<number>(0);

    const handleClick = (e, titleProps) => {
        const { index } = titleProps;
        const activeIndexT = activeIndex;
        const newIndex = activeIndexT === index ? -1 : index;
        setActiveIndex(newIndex);
    };

    const [isFinal, setIsFinal] = useState<boolean>(true);

    const { siteInspectionReportStore } = useStore();
    const { createSiteInspectionReport, updateSiteInspectionReport, loadSiteInspectionReport, loadingInitial } = siteInspectionReportStore;
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [report, setReport] = useState<SiteInspectionReportFormValues>(new SiteInspectionReportFormValues());

    const validationSchema = Yup.object({
        inspectionDate: Yup.date().required("Inspection Date is required"),
        oblastId: Yup.string().required("Oblast is required"),
        spNameId: Yup.string().required("SP Name is required"),
        percentCompleted: Yup.number()
            .nullable()
            .min(0, "Percent Completed must be at least 0")
            .max(100, "Percent Completed cannot be more than 100")
            .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
    });

    useEffect(() => {
        if (id) {
            loadSiteInspectionReport(id).then(sReport => setReport(new SiteInspectionReportFormValues(sReport)))
        }
    }, [id, loadSiteInspectionReport]);

    function handleFormSubmit(report: SiteInspectionReportFormValues) {
        //console.log("report", report);
        console.log('percentCompleted', report.percentCompleted);
        report.isFinal = isFinal;
        if (String(report.percentCompleted).trim() === '') {
            report.percentCompleted = null;
        }
        if (!report.id) {
            const newId = uuid();
            let newReport = {
                ...report,
                id: newId
            };
            createSiteInspectionReport(newReport).then(() => navigate(`/reports/${newId}`));
        } else {
            updateSiteInspectionReport(report).then(() => navigate(`/reports/${report.id}`))
        }
    }

    if (loadingInitial) return <LoadingComponent content='Loading report...' />


    return (
        <Segment clearing style={{ backgroundColor: "transparent", border: "0px " }}>
            <Header content='Site Inspection Report Details' sub color='teal' />
            <Formik
                validationSchema={validationSchema}
                enableReinitialize
                initialValues={report}
                onSubmit={(values) => handleFormSubmit(values)}>
                {formikProps => {
                    // Destructure the Formik props you want to pass down
                    const { handleSubmit, isValid, isSubmitting, dirty } = formikProps;
                    return (
                        <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                            <Card fluid key={siteInspectionReportStore.title}  >
                                <Card.Content style={{ color: 'teal' }}>
                                    <h1>{siteInspectionReportStore.title}</h1>
                                </Card.Content>
                                <Card.Content extra key="GeneralInfo">
                                    <Accordion fluid styled>
                                        <Accordion.Title
                                            active={activeIndex === 0}
                                            index={0}
                                            onClick={handleClick}
                                            className="accordion"
                                        >
                                            <div className="accordion-title">
                                                <Icon name="dropdown" />
                                                General Information
                                            </div>
                                        </Accordion.Title>
                                        <Accordion.Content active={activeIndex === 0}>
                                            <GeneralInformation report={report} readOnly={false} formikProps={formikProps } />
                                        </Accordion.Content>
                                        <Accordion.Title
                                            active={activeIndex === 1}
                                            index={1}
                                            onClick={handleClick}
                                            className="accordion"
                                        >
                                            <div className="accordion-title">
                                                <Icon name="dropdown" />
                                                Health & Safety
                                            </div>
                                        </Accordion.Title>
                                        <Accordion.Content active={activeIndex === 1}>
                                            <HsObservation report={report} readOnly={false} formikProps={formikProps} />
                                        </Accordion.Content>

                                        <Accordion.Title
                                            active={activeIndex === 2}
                                            index={2}
                                            onClick={handleClick}
                                        >
                                            <div className="accordion-title">
                                                <Icon name="dropdown" />
                                                Ongoing Activities
                                            </div>
                                        </Accordion.Title>
                                        <Accordion.Content active={activeIndex === 2}>
                                            <OngoingActivities
                                                report={report}
                                                readOnly={false}
                                                formikProps={formikProps}
                                            />
                                        </Accordion.Content>

                                        <Accordion.Title
                                            active={activeIndex === 3}
                                            index={3}
                                            onClick={handleClick}
                                        >
                                            <div className="accordion-title">
                                                <Icon name="dropdown" />
                                                Observance on Ongoing Activities
                                            </div>
                                        </Accordion.Title>
                                        <Accordion.Content active={activeIndex === 3}>
                                            <ObservanceOnOngoingActivities report={report} readOnly={false} formikProps={formikProps} />
                                        </Accordion.Content>

                                        <Accordion.Title
                                            active={activeIndex === 4}
                                            index={4}
                                            onClick={handleClick}
                                        >
                                            <div className="accordion-title">
                                                <Icon name="dropdown" />
                                                Quality and Progress
                                            </div>
                                        </Accordion.Title>
                                        <Accordion.Content active={activeIndex === 4}>
                                            <QualityProgress report={report} readOnly={false} formikProps={formikProps} />
                                        </Accordion.Content>
                                        <Accordion.Title
                                            active={activeIndex === 5}
                                            index={5}
                                            onClick={handleClick}
                                        >
                                            <div className="accordion-title">
                                                <Icon name="dropdown" />
                                                Progress
                                            </div>
                                        </Accordion.Title>
                                        <Accordion.Content active={activeIndex === 5}>
                                            <Progress report={report} readOnly={false} />
                                        </Accordion.Content>
                                    </Accordion>
                                </Card.Content>
                                <Card.Content extra className="main-button-controls" key="SubmitButtons">
                                    <Button
                                        disabled={isSubmitting || !isValid || !dirty}
                                        loading={isSubmitting}
                                        type='submit'
                                        content='Save Draft'
                                        icon="edit"
                                        onClick={() => setIsFinal(false)}
                                        style={{ background: "#fff", color: "#2364AA", border: '1px solid #2364AA' }}
                                    >
                                    </Button>
                                    <Button
                                        disabled={isSubmitting || !isValid || !dirty}
                                        loading={isSubmitting}
                                        positive type='submit'
                                        content='Submit'
                                        icon="check"
                                        onClick={() => setIsFinal(true)}
                                        style={{ background: "green", color: "#fff", border: '1px solid green' }}
                                    >
                                    </Button>
                                    <Button
                                        as={Link}
                                        to='/reports'
                                        floated='right'
                                        type='button'
                                        content='Cancel'
                                        basic
                                        color='red'
                                    />
                                </Card.Content>
                            </Card>
                        </Form>
                    );
                }}
            </Formik>
        </Segment>
    );
});
