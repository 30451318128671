import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material/';
import { Grid, FormControl, RadioGroup, FormLabel, Radio, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import SirImageUpload from '../../app/common/SirImageUpload';
import SirTextArea from '../../app/common/SirTextArea';
import SirTextInput from '../../app/common/SirTextInput';
import { FormikProps } from 'formik';
import { SiteInspectionReportFormValues } from '../../app/models/siteinspectionreport';
import { OngoingActivity } from '../../app/models/ongoingactivity';

interface OngoingActivitiesDialogBoxProps {
    formikProps: FormikProps<SiteInspectionReportFormValues>;
    activityIndex?: number; // Index of the activity being edited, if applicable
    handleClose: () => void;
    open: boolean;
}

const OngoingActivitiesDialogBox: React.FC<OngoingActivitiesDialogBoxProps> = ({ formikProps, activityIndex, handleClose, open }) => {
    const isCreatingNew = activityIndex === undefined;
    const fieldNamePrefix = isCreatingNew ? 'newActivity' : `ongoingActivities[${activityIndex}]`;

    const useStyles = makeStyles((theme) => ({
        root: {
            "& .MuiFormControl-root": {
                width: "90%",
                margin: theme.spacing(1),
            },
        },
    }));
    const classes = useStyles();

    const handleSave = () => {
        if (isCreatingNew) {
            const values: any = formikProps.values;
            // Logic to add a new activity to the ongoingActivities array
            formikProps.setFieldValue('ongoingActivities', [...formikProps.values.ongoingActivities!, values.newActivity]);
        } else {
            let updatedActivities = [...formikProps.values.ongoingActivities!];
            updatedActivities[activityIndex] = formikProps.values.ongoingActivities![activityIndex];
            formikProps.setFieldValue('ongoingActivities', updatedActivities);
        }
        handleClose(); // Close the dialog box
    };

    const extendedValues = formikProps.values as SiteInspectionReportFormValues & { newActivity?: OngoingActivity };

    const radioValue = isCreatingNew
        ? extendedValues.newActivity?.perSchedule?.toString() || ''
        : extendedValues.ongoingActivities![activityIndex]?.perSchedule?.toString() || 'false';

    //const radioValue = activityIndex !== undefined ? formikProps.values.ongoingActivities![activityIndex]?.perSchedule?.toString() : 'false';

    return (
        <div>
            <Dialog
                id="form-dialog-title"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {isCreatingNew ? "Create new activity" : "Update Ongoing Activity"}
                </DialogTitle>
                <form className={classes.root}>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <SirTextInput
                                    name={`${fieldNamePrefix}.block`}
                                    placeholder="Add Block/Location"
                                    label="Block/Location"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel>Per Schedule</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-label="Per Schedule"
                                        name={`${fieldNamePrefix}.perSchedule`}
                                        value={radioValue}
                                        onChange={(e) => {
                                            const value = e.target.value === 'true';
                                            formikProps.setFieldValue(`${fieldNamePrefix}.perSchedule`, value);
                                        }}
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="false" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <SirTextArea
                                    name={`${fieldNamePrefix}.perScheduleComments`}
                                    placeholder="Per Schedule Comments"
                                    minRows={2}
                                    maxRows={4}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SirTextArea
                                    name={`${fieldNamePrefix}.descriptionOfWorks`}
                                    placeholder="Description of Works (Per BOQ Item)"
                                    minRows={2}
                                    maxRows={4}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SirTextArea
                                    name={`${fieldNamePrefix}.remarks`}
                                    placeholder="Remarks"
                                    minRows={2}
                                    maxRows={6}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SirTextInput
                                    name={`${fieldNamePrefix}.photoName`}
                                    placeholder="Photo Name"
                                    label="Name of Photo"
                                />
                                <SirImageUpload name={`${fieldNamePrefix}.photo`} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary" variant="outlined">Cancel</Button>
                        <Button onClick={() => handleSave()} color="primary" variant="contained">
                            {isCreatingNew ? 'Create' : 'Update'}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div >
    );
};

export default OngoingActivitiesDialogBox;