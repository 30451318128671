import { useRef } from 'react';
import { toJS } from 'mobx'
import { Grid, Button } from '@material-ui/core'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { v4 as uuid } from 'uuid';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import React from 'react';
import { SirHealthSafetyObservation } from '../../app/models/sirhealthsafetyobservation';
import { SiteInspectionReportFormValues } from '../../app/models/siteinspectionreport';
import { makeStyles } from "@material-ui/core/styles";
import { FormikProps } from 'formik';
import HealthAndSafetyDialogBox from './HealthAndSafetyDiaolgBox';
import ConfirmationDialog from '../UI/ConfirmationDialog';

const useStyles = makeStyles({
    root: {
        background: "#278DAD",
        borderRadius: 3,
        border: 0,
        color: "white",
        height: 40,
        padding: "0 30px",
        boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
        '&:hover': {
            backgroundColor: '#D7C333',
            color: '#3c52b2'
        }
    },
    label: {
        textTransform: "capitalize"
    }
});

const arr: SirHealthSafetyObservation[] = [];

const HsObservation = ({
    report,
    readOnly = false,
    formikProps = {} as FormikProps<SiteInspectionReportFormValues>}: {
        report: SiteInspectionReportFormValues,
        readOnly: boolean
        formikProps: FormikProps<SiteInspectionReportFormValues>
    }) => {

    const classes = useStyles();

    const checkObs = () => {
        if (report.sirHealthSafetyObservations !== undefined && report.sirHealthSafetyObservations !== null) {
            report.sirHealthSafetyObservations = toJS(report.sirHealthSafetyObservations);
        } else {
            report.sirHealthSafetyObservations = arr;
        }
    }
    checkObs();
    let nextId = uuid()

    const [editingHealthAndSafetyIndex, setEditingHealthAndSafetyIndex] = React.useState<number | undefined>(undefined);

    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
    const [healthAndSafetyToRemove, setHealthAndSafetyToRemove] = React.useState(null);

    const initialValues: SirHealthSafetyObservation = {
        id: nextId,
        healthSafetyObservationId: 0,
        comment: "",
        healthSafetyObservationText: "",
        siteInspectionReportId: report.id
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const readOnlyColumnDefs = [
        { headerName: "ID", field: "id", hide: true },
        { headerName: "Observation Type", field: "healthSafetyObservationText", flex: 2 },
        { headerName: "Comment", field: "comment", flex: 3 }
    ];

    const editableColumnDefs = [
        { headerName: "ID", field: "id", hide: true },
        { headerName: "Observation Type", field: "healthSafetyObservationText", resizable: true },
        { headerName: "Comment", field: "comment", flex: 3 },
        {
            headerName: "Actions",
            field: "id", resizable: true,
            cellRenderer: (params) =>
                <div>
                    <Button color="primary" onClick={() => onEdit(params.rowIndex)} ><ModeEditOutlineOutlinedIcon /></Button>
                    <Button color="secondary" onClick={() => onRemove(params.rowIndex)}><DeleteOutlinedIcon /></Button>
                </div>
        }
    ]

    const gridRef = useRef<AgGridReact>(null);

    const defaultColDef = {
        sortable: true,
        flex: 1, filter: true,
    }

    const onEdit = (index: number) => {
        setEditingHealthAndSafetyIndex(index);
        handleClickOpen();
    }

    const handleCreateSirHealthSafetyObservation = () => {
        formikProps.setFieldValue('newSirHealthSafetyObservation', initialValues);
        setEditingHealthAndSafetyIndex(undefined);
        handleClickOpen();
    }

    const onRemove = (index) => {
        setHealthAndSafetyToRemove(index);
        setIsConfirmDialogOpen(true);
    };

    const handleConfirmDelete = () => {
        const updatedSirHealthSafetyObservations = formikProps.values.sirHealthSafetyObservations!.filter((_, i) => i !== healthAndSafetyToRemove);
        formikProps.setFieldValue('sirHealthSafetyObservations', updatedSirHealthSafetyObservations);

        setIsConfirmDialogOpen(false);
        setHealthAndSafetyToRemove(null);
    };

    return (
        <div className="App">
            {readOnly ? null : (
                <Grid container>
                    <Grid>
                        <Button
                            variant="contained"
                            onClick={() => { handleCreateSirHealthSafetyObservation(); }}
                            classes={{
                                root: classes.root,
                                label: classes.label
                            }}
                        >
                            Add New HSE Observation
                        </Button>
                    </Grid>
                </Grid>
            )}
            <div className="ag-theme-alpine" style={{ height: '300px' }}>
                <AgGridReact
                    ref={gridRef}
                    rowData={formikProps.values.sirHealthSafetyObservations}
                    columnDefs={readOnly ? readOnlyColumnDefs : editableColumnDefs}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    pagination={true}
                    rowSelection='multiple'
                    paginationPageSize={4}
                />
            </div>
            <HealthAndSafetyDialogBox
                formikProps={formikProps}
                healthAndSafetyIndex={editingHealthAndSafetyIndex}
                open={open}
                handleClose={handleClose}
            />
            <ConfirmationDialog
                open={isConfirmDialogOpen}
                onClose={() => setIsConfirmDialogOpen(false)}
                onConfirm={handleConfirmDelete}
            />

        </div>
    );
}

export default React.memo(HsObservation);