import { Oblast } from "./oblast";
import { SpName } from "./spname";
import { SirHealthSafetyObservation } from "./sirhealthsafetyobservation";
import { OngoingActivity } from "./ongoingactivity";
import { ObservanceOngoingActivity } from "./observanceongoingactivity";
import { QualityAndProgress } from "./qualityandprogress";

export interface SiteInspectionReport {
    id: string;
    code: string;
    oblastId: number | null;
    oblast: Oblast | null;
    oblastString: string | null;
    spNameId: number | null;
    spNameString: string;
    spName: SpName | null;
    spCode: string;
    versionId: number;
    inspectionDate: Date | null;
    contractor: string;
    contractorRepresentative: string;
    usifRepresentative: string;
    skilledLabor: number | null;
    unskilledLabor: number | null;
    weatherConditions: string;
    sirHealthSafetyObservations: SirHealthSafetyObservation[] | null;
    ongoingActivities: OngoingActivity[] | null;
    observanceOngoingActivities: ObservanceOngoingActivity[] | null;
    qualityAndProgresses: QualityAndProgress[] | null;
    percentCompleted: number | null;
    plannedCompletionDate: Date | null;
    isFinal: boolean;
    coordinateX: number | null;
    coordinateY: number | null;
    createdBy: string | null;
    m4hStaff: string | null;

}

export class SiteInspectionReport implements SiteInspectionReport {
    constructor(init?: SiteInspectionReportFormValues) {
        Object.assign(this, init);
    }
}

export class SiteInspectionReportFormValues {
    id?: string = undefined;
    code: string | null = '';
    oblastId: number | null = null;
    oblast?: Oblast | null = null;
    oblastString?: string | null = '';
    spNameId: number | null = null;
    spName?: SpName | null = null;
    spNameString?: string = '';
    spCode: string = '';
    versionId?: number = 0;
    inspectionDate?: Date | null = null;
    contractor: string = '';
    contractorRepresentative: string = '';
    usifRepresentative: string = '';
    skilledLabor: number | null = null;
    unskilledLabor: number | null = null;
    weatherConditions: string = '';
    sirHealthSafetyObservations?: SirHealthSafetyObservation[] | null = null;
    ongoingActivities?: OngoingActivity[] | null = null;
    observanceOngoingActivities?: ObservanceOngoingActivity[] | null = null;
    qualityAndProgresses?: QualityAndProgress[] | null = null;
    percentCompleted?: number | null = null;
    plannedCompletionDate?: Date | null = undefined;
    isFinal?: boolean = false;
    coordinateX?: number | any | null = null;
    coordinateY?: number | any |  null = null;
    createdBy: string | null = '';
    m4hStaff: string | null = '';

    constructor(siteInspectionReport?: SiteInspectionReportFormValues) {
        if (siteInspectionReport) {
            this.id = siteInspectionReport.id;
            this.code = siteInspectionReport.code;
            this.oblastId = siteInspectionReport.oblastId;
            this.oblast = siteInspectionReport.oblast;
            this.oblastString = siteInspectionReport.oblastString;
            this.spNameId = siteInspectionReport.spNameId;
            this.spName = siteInspectionReport.spName;
            this.spNameString = siteInspectionReport.spNameString;
            this.spCode = siteInspectionReport.spCode ? siteInspectionReport.spCode : '';
            this.versionId = siteInspectionReport.versionId;
            this.inspectionDate = siteInspectionReport.inspectionDate;
            this.contractor = siteInspectionReport.contractor ? siteInspectionReport.contractor : '';
            this.contractorRepresentative = siteInspectionReport.contractorRepresentative ? siteInspectionReport.contractorRepresentative : '';
            this.usifRepresentative = siteInspectionReport.usifRepresentative ? siteInspectionReport.usifRepresentative : '';
            this.skilledLabor = siteInspectionReport.skilledLabor;
            this.unskilledLabor = siteInspectionReport.unskilledLabor;
            this.weatherConditions = siteInspectionReport.weatherConditions;
            this.sirHealthSafetyObservations = siteInspectionReport.sirHealthSafetyObservations;
            this.ongoingActivities = siteInspectionReport.ongoingActivities;
            this.observanceOngoingActivities = siteInspectionReport.observanceOngoingActivities;
            this.qualityAndProgresses = siteInspectionReport.qualityAndProgresses;
            this.percentCompleted = siteInspectionReport.percentCompleted;
            this.plannedCompletionDate = siteInspectionReport.plannedCompletionDate;
            this.isFinal = siteInspectionReport.isFinal;
            this.coordinateX = siteInspectionReport.coordinateX;
            this.coordinateY = siteInspectionReport.coordinateY;
            this.createdBy = siteInspectionReport.createdBy;
            this.m4hStaff = siteInspectionReport.m4hStaff;
        }
    }
}

