import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Header, Segment, Image, Button } from 'semantic-ui-react';
import { useStore } from '../../app/stores/store';
import LoginForm from '../users/LoginForm';

export default observer(function HomePage() {
    const { userStore, modalStore } = useStore();
    return (
        <Segment inverted textAlign='center' vertical className='masthead'>
            <Container text style={{ border: "none" }}>
                <Header as='h1' inverted>
                    {/*<Image size='massive' src='../../assets/m4h-logo-web.png' alt='logo' style={{ marginBottom: 12 }} />*/}
                    USIF OMS
                </Header>
                <Header as='h2' inverted>Online Monitoring System</Header>
                {userStore.isLoggedIn ? (
                    <>
                        <Button as={Link} to='/dashboard' size='huge' inverted>
                            Go to Dashboard
                        </Button>
                    </>

                ) : (
                    <>
                        <Button onClick={() => modalStore.openModal(<LoginForm />)} size='huge' inverted>
                            Login!
                        </Button>
                    </>

                )}
            </Container>
        </Segment>
    )
})