import React from "react";
import { Card, Grid, Header, Segment } from "semantic-ui-react";
import GeneralInformation from "./GeneralInformation";
import Progress from './Progress';
import HsObservation from './HealthAndSafetyForm/HsObservation';
import OngoingActivities from './OngoingActivities/OngoingActivities';
import ObservanceOnOngoingActivities from './ObservanceOnOngoingActivities/ObservanceOnOngoingActivities';
import QualityProgress from './QualityAndProgress/QualityAndProgress';
import { Formik, Form } from "formik";
import { SiteInspectionReportFormValues } from "../app/models/siteinspectionreport";
import { observer } from "mobx-react-lite";

const CardFormView = ({ formValues }: { formValues: SiteInspectionReportFormValues }) => {
    return (
        <div style={{ paddingBottom: '260px' }}>
            <Segment
                clearing
                style={{
                    minHeight: `calc(100vh - 260px)`,
                    backgroundColor: "transparent",
                    border: "0px",
                }}
            >
                <Header content='Site Inspection Report Details' sub color='teal' />
                <Formik
                    enableReinitialize
                    initialValues={formValues}
                    onSubmit={() => { }}>
                    {formikProps => {
                        // Destructure the Formik props you want to pass down
                        return (
                            <Form className='ui form'>
                                <Card fluid className="card-form">
                                    <Card.Content>
                                        <Card.Header className="card-header" content={`${formValues.spNameString ? formValues.spNameString : ''} - ${formValues.spCode}`} style={{ color: 'teal' }} />
                                        <Card.Description>
                                            <Grid columns={1}>
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <GeneralInformation report={formValues} readOnly formikProps={formikProps} />
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <HsObservation report={formValues} readOnly formikProps={formikProps} />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <OngoingActivities report={formValues} readOnly formikProps={formikProps} />
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <ObservanceOnOngoingActivities report={formValues} readOnly formikProps={formikProps} />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <QualityProgress report={formValues} readOnly formikProps={formikProps} />
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <Progress report={formValues} readOnly />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Card.Description>
                                    </Card.Content>
                                </Card>
                            </Form>
                        );
                    }}
                </Formik>
            </Segment>
        </div>
    );
};

export default observer(CardFormView);
