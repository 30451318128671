import { useField } from 'formik';
import React from 'react';
import { Form, Label } from 'semantic-ui-react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function SirDateInput(props: Partial<ReactDatePickerProps>) {
    const [field, meta, helpers] = useField(props.name!);

    const selectedDate = field.value ? new Date(field.value) : null;
    const formattedDate = selectedDate ? selectedDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }) : '';

    return (
        <Form.Field error={meta.touched && !!meta.error}>
            <DatePicker
                {...field}
                {...props}
                dateFormat='dd MMM yyyy'
                selected={selectedDate}
                onChange={(value) => helpers.setValue(value)}
                value={formattedDate }
            />
            {meta.touched && meta.error ? (
                <Label basic color='red'>{meta.error}</Label>
            ) : null}
        {/*    {formattedDate && <div>{formattedDate}</div>}*/}
        </Form.Field>
    );
}
