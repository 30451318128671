import React from 'react';
import { useField } from 'formik';
import { IconButton } from '@material-ui/core';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { Image } from 'semantic-ui-react';

interface ImageUploadProps {
    name: string;
}

export default function SirImageUpload({ name }: ImageUploadProps) {
    const [field, , helpers] = useField(name);
    const [preview, setPreview] = React.useState("");

    React.useEffect(() => {
        // If there's an existing value and it's a base64 string, set it as the preview source
        if (typeof field.value === 'string' && field.value.startsWith('/9j/')) {
            //setPreview(`data:${file.type};base64,${base64Data.split(',')[1]}`);
            setPreview(`data:image/jpeg;base64,${field.value}`);
        } else {
            // If it does, just use it as is
            setPreview(field.value);
        }
    }, [field.value]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Data = reader.result as string;
                helpers.setValue(base64Data);
                // Ensure the base64 string has the correct prefix
                if (!base64Data.startsWith('data:')) {
                    setPreview(`data:${file.type};base64,${base64Data.split(',')[1]}`);
                } else {
                    setPreview(base64Data);
                }
                helpers.setValue(base64Data);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDownload = () => {
        // Extract the MIME type from the base64 string and use it to construct the filename
        const mimeType = preview.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
        let filename = 'download';

        if (mimeType && mimeType.length > 1) {
            // Split the MIME type at the '/' and use the second part (e.g., 'jpeg' or 'png') as the file extension
            const extension = mimeType[1].split('/')[1];
            filename = `image.${extension}`;
        }

        // Create a temporary anchor tag to trigger download
        const element = document.createElement("a");
        element.href = preview;
        element.download = filename; // Use the dynamic filename here
        document.body.appendChild(element); // Append to body for Firefox compatibility
        element.click();
        document.body.removeChild(element);
    };

    return (
        <div>
            <input
                type="file"
                accept=".jpeg, .png, .jpg"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id={name} // Add id matching htmlFor in the label
            />
            <label htmlFor={name}>
                <IconButton
                    color="secondary"
                    aria-label="Upload picture"
                    component="span"
                    size="medium"
                >
                    <PhotoCameraIcon />
                </IconButton>
            </label>
            {preview && (
                <a
                    href={preview}
                    onClick={handleDownload}
                    style={{ cursor: 'pointer' }}
                    download="image.jpg" // Or use the dynamic filename as in your `handleDownload` function
                >
                    <Image
                        src={preview as string}
                        size='small'
                        style={{ marginBottom: 5 }}
                    />
                </a>
            )}
        </div>
    );
}
