import * as React from 'react';
import "./GeneralInformation";
import { FormField, Grid } from "semantic-ui-react";
import AppMap from "./Map/AppMap";
import { Oblast } from "../app/models/oblast";
import { SpName } from "../app/models/spname";
import SirDateInput from '../app/common/SirDateInput';
import SirTextInput from '../app/common/SirTextInput';
import SirSelectInput from '../app/common/SirSelectInput';
import { useStore } from '../app/stores/store';
import { SiteInspectionReportFormValues } from '../app/models/siteinspectionreport';
import { FormikProps } from 'formik';
import { Disbursement } from '../app/models/disbursement';

interface Res {
    text: string;
    value: number;
}

const GeneralInformation = ({
    report,
    readOnly = false,
    formikProps = {} as FormikProps<SiteInspectionReportFormValues> }: {
        report: SiteInspectionReportFormValues,
        readOnly: boolean,
        formikProps: FormikProps<SiteInspectionReportFormValues>
    }) => {

    const [getOblast, setOblast] = React.useState<Oblast[]>([]);
    const [getSpName, setSpName] = React.useState<SpName[]>([]);
    const [data, setData] = React.useState({
        coordinateX: report.coordinateX,
        coordinateY: report.coordinateY,
        mapReadonly: readOnly
    });
    const { refDataStore } = useStore();

    React.useEffect(() => {
        refDataStore.loadOblasts().then((res) => setOblast(res ? res : []))
            .catch((error) => console.log(error));
    }, []);

    let results: Res[] = [];
    let spNames: Res[] = [];
    // CoordinateY should be the Lattitude, meanwhile CoordinateX should be Longitude

    const getSpFromOblast = (oblastId: number) => {
        refDataStore.loadSpNames(oblastId).then((res) => {
            if (res) {
                setSpName(res.data);
                mapSpNameToResults();
            }
        });
    }

    const mapOblastToResult = () => {
        getOblast.forEach(oblast => {
            let r = {} as Res;
            r.text = oblast.name;
            r.value = oblast.id;
            results.push(r);
        })
    }

    const mapSpNameToResults = () => {
        getSpName.forEach(spName => {
            let r = {} as Res;
            r.text = spName.name;
            r.value = spName.id;
            spNames.push(r);
        })
        return spNames;
    }
    React.useEffect(() => {
        if (report) {
            if (report.oblastId) {
                getSpFromOblast(report.oblastId);
            }
        //    if (report.spNameId) {
        //        mapSpToSpCode(report.spNameId);
        //    }
        }
    }, [report])

    const mapSpToSpCode = async (spId: number) => {
        try {
            const res = await refDataStore.loadSpNameDetails(spId);
            //refDataStore.loadSpNameDetails(spId).then((res) => {
            if (res) {
                // Update Formik's state
                formikProps.setFieldValue('spCode', res.data.spCode);
                formikProps.setFieldValue('contractor', res.data.contractor);
                formikProps.setFieldValue('contractorRepresentative', res.data.contractorRepresentative);
                formikProps.setFieldValue('usifRepresentative', res.data.usifRepresentative);

                // Update the data state
                setData({
                    ...data,
                    coordinateY: res.data.coordinateY,
                    coordinateX: res.data.coordinateX
                });
            //    formikProps.setFieldValue('coordinateY', res.data.coordinateY);
            //    formikProps.setFieldValue('coordinateX', res.data.coordinateX);
            }
        }
        catch (error) {
            console.error('Error loading SP Name details:', error);
        }
    }

    const handleCoordinateChanges = () => {
        formikProps.setFieldValue('coordinateY', data.coordinateY);
        formikProps.setFieldValue('coordinateX', data.coordinateX);
    }

    mapOblastToResult();
    mapSpNameToResults();
    mapSpToSpCode(0);

    return (
        <>
            <Grid columns={3} stackable>
                <Grid.Row>
                    <Grid.Column>
                        <FormField>
                            <SirSelectInput options={results} placeholder='Oblast' name='oblastId' onChange={getSpFromOblast} label='Oblast' disabled={readOnly} />
                        </FormField>
                    </Grid.Column>
                    <Grid.Column>
                        <FormField>
                            <SirSelectInput options={spNames} placeholder='SP Name' name='spNameId' onChange={mapSpToSpCode} label='SP Name' disabled={readOnly} />
                        </FormField>
                    </Grid.Column>
                    <Grid.Column>
                        <FormField>
                            <SirTextInput
                                name='spCode'
                                placeholder='SP Code'
                                disabled={readOnly}
                                label='SP Code' />
                        </FormField>
                    </Grid.Column>
                    <Grid.Column>
                        <FormField>
                            <label>Inspection Date</label>
                            <SirDateInput name='inspectionDate'
                                placeholderText='Inspection Date'
                                dateFormat='MMMM d, yyyy'
                                disabled={readOnly}
                            />
                        </FormField>
                    </Grid.Column>
                    <Grid.Column>
                        <FormField>
                            <SirTextInput
                                name='contractor'
                                placeholder='Contractor'
                                label='Contractor'
                                disabled={readOnly} />
                        </FormField>
                    </Grid.Column>
                    <Grid.Column>
                        <FormField>
                            <SirTextInput
                                name='contractorRepresentative'
                                placeholder='Contractor Representative'
                                label="Contractor's representative"
                                disabled={readOnly} />
                        </FormField>
                    </Grid.Column>
                    <Grid.Column>
                        <FormField>
                            <SirTextInput name='m4hStaff' placeholder='M4H Staff' label='M4H Staff' disabled={readOnly} />
                        </FormField>
                    </Grid.Column>
                    <Grid.Column>
                        <FormField>
                            <SirTextInput
                                name='usifRepresentative'
                                placeholder='USIF Representative'
                                label='USIF representative'
                                disabled={readOnly} />
                        </FormField>
                    </Grid.Column>
                    <Grid.Column>
                        <FormField>
                            <SirTextInput
                                name='skilledLabor'
                                placeholder='Skilled Labor'
                                label='Skilled Labor'
                                type="number"
                                disabled={readOnly} />
                        </FormField>
                    </Grid.Column>
                    <Grid.Column>
                        <FormField>
                            <SirTextInput
                                name='unskilledLabor'
                                placeholder='Unskilled Labor'
                                label='Unskilled Labor'
                                type="number"
                                disabled={readOnly} />
                        </FormField>
                    </Grid.Column>
                    <Grid.Column>
                        <FormField>
                            <SirTextInput
                                name='weatherConditions'
                                placeholder='Weather Conditions'
                                label='Weather Conditions'
                                disabled={readOnly} />
                        </FormField>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <AppMap data={data} handleCoordinateChanges={handleCoordinateChanges} />
        </>
    );
};

export default GeneralInformation;
