import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { PaginatedResult } from '../models/pagination';
import { SiteInspectionReport, SiteInspectionReportFormValues } from '../models/siteinspectionreport';
import { User, UserFormValues, UpdatePasswordFormValues, EditUserFormValues, UpdateOtherPasswordFormValues, ForgotPasswordFormValues, ResetPasswordFormValues } from '../models/user';
import { Oblast } from '../models/oblast';
import { store } from '../stores/store';
import { SpName, SpNameFormValues } from '../models/spname';
import { HealthSafetyObservation } from '../models/healthsafetyobservation';
import { Profile } from '../models/profile'
import { IssueType } from '../models/issuetype';
import { router } from '../router/Routes';
import { Role } from '../models/role';
import { Disbursement, DisbursementFormValues } from '../models/disbursement';
import { SpNameStatus } from '../models/spnamestatus';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(async response => {
    const pagination = response.headers['pagination'];
    if (pagination) {
        response.data = new PaginatedResult(response.data, JSON.parse(pagination));
        return response as AxiosResponse<PaginatedResult<any>>
    }
    //console.log('Response received from', response.config.url, 'with headers:', response.headers);
    return response;
}, (error: AxiosError) => {
    const { data, status, config } = error.response as AxiosResponse
    switch (status) {
        case 400:
            if (config.method === 'get' && data.errors.hasOwnProperty('id')) {
                router.navigate('/not-found');
            }
            if (data.errors) {
                const modalStateErrors = [];
                const results: [] = data.errors;
                for (const key in results) {
                    if (results[key]) {
                        modalStateErrors.push(results[key])
                    }
                }
                throw modalStateErrors.flat();
            } else {
                toast.error(data);
            }
            break;
        case 401:
            toast.error('unauthorised')
            break;
        case 404:
            router.navigate('/not-found');
            break;
        case 500:
            store.commonStore.setServerError(data);
            router.navigate('/server-error');
            break;
    }
    //if (error.response) {
    //    console.error('Error response from', error.response.config.url, ':', error.response);
    //} else {
    //    console.error('Error sending request:', error.message);
    //}
    return Promise.reject(error);
})

axios.interceptors.request.use(config => {
    const token = store.commonStore.token;
    if (token && config.headers) config.headers.Authorization = `Bearer ${token}`
    //console.log(`Sending request to ${config.url}:`, config);
    return config;
}, error => {
    // Do something with request error
    return Promise.reject(error);
});

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
    dnl: (url: string) => axios.get(url, { responseType: 'blob' }).then(responseBody)
}

const SiteInspectionReports = {
    list: (params: URLSearchParams) => axios.get<PaginatedResult<SiteInspectionReport[]>>('/siteinspectionreports', { params })
        .then(responseBody),
    details: (id: string) => requests.get<SiteInspectionReport>(`/siteinspectionreports/${id}`),
    create: (siteInspectionReport: SiteInspectionReportFormValues) => requests.post<void>('/SiteInspectionReports/createsiteinspectionreport', siteInspectionReport),
    update: (siteInspectionReport: SiteInspectionReportFormValues) => requests.put<void>(`/siteinspectionreports/${siteInspectionReport.id}`, siteInspectionReport),
    delete: (id: string) => requests.del<void>(`/reports/${id}`),
    download: (id: string) => requests.dnl(`/documentGeneration/generatesirreport?id=${id}`)
}

const Disbursements = {
    list: (params: URLSearchParams) => axios.get<PaginatedResult<Disbursement[]>>('/disbursements', { params })
        .then(responseBody),
    details: (id: string) => requests.get<Disbursement>(`/disbursements/${id}`),
    create: (disbursement: DisbursementFormValues) => requests.post<void>('/disbursements/createdisbursement', disbursement),
    update: (disbursement: DisbursementFormValues) => requests.put<void>(`/disbursements/${disbursement.id}`, disbursement),
    delete: (id: string) => requests.del<void>(`/disbursements/${id}`)
}

const Account = {
    current: () => requests.get<User>('/account'),
    login: (user: UserFormValues) => requests.post<User>('account/login', user),
    register: (user: UserFormValues) => requests.post<User>('account/register', user),
    refreshToken: () => requests.post<User>('/account/refreshToken', {}),
    changePassword: (passwordDetails: UpdatePasswordFormValues) => requests.post<UpdatePasswordFormValues>('account/changepassword', passwordDetails),
    changeOtherPassword: (passwordDetails: UpdateOtherPasswordFormValues) => requests.post<UpdateOtherPasswordFormValues>('account/changeotherpassword', passwordDetails),
    forgotPassword: (details: ForgotPasswordFormValues) => requests.post<ForgotPasswordFormValues>('account/forgot-password', details),
    resetPassword: (resetDetails: ResetPasswordFormValues) => requests.post<ResetPasswordFormValues>('account/reset-password', resetDetails),
    createCubeJsToken: () => requests.post<string>('account/createcubejstoken', {})
}

const Roles = {
    list: () => requests.get<Role[]>(`/role/getroles`)
}

const Users = {
    list: () => requests.get<User[]>('/Account/getallusers'),
    updateuser: (user: EditUserFormValues) => requests.post<User>('Account/updateuser', user)
}

const Oblasts = {
    list: () => requests.get<Oblast[]>('/oblast/getoblasts')
}

const IssueTypes = {
    list: () => requests.get<IssueType[]>('/issuetype/getissuetypes')
}

const SpNames = {
    list: (oblastId: number) => axios.get<SpName[]>(`/spname/getsubprojectbyoblastid?oblastId=${oblastId}`),
    listallsubprojects: () => requests.get<SpName[]>('/spname/getallsubprojects'),
    getbyid: (id: number) => axios.get<SpName>(`/spname/getsubprojectbyid?id=${id}`),
    update: (spName: SpNameFormValues) => requests.put<void>(`/spname/${spName.id}`, spName),
    create: (spName: SpNameFormValues) => requests.post<void>('/spname/createsubproject', spName),
    listspnamestatuses: () => requests.get<SpNameStatus[]>('/spnamestatus/getspnamestatuses'),

}

const HealthAndSafetyObservations = {
    list: () => requests.get<HealthSafetyObservation[]>('/HealthSafetyObservation/getlist')
}

const Profiles = {
    get: (username: string) => requests.get<Profile>(`/profile/${username}`),
    updateProfile: (profile: Partial<Profile>) => requests.put(`/profile`, profile)
}

const agent = {
    SiteInspectionReports,
    Account,
    Users,
    Roles,
    Oblasts,
    IssueTypes,
    SpNames,
    HealthAndSafetyObservations,
    Profiles,
    Disbursements
}

export default agent;