import * as React from 'react';
import { observer } from "mobx-react-lite"
import { useStore } from '../../app/stores/store';
import { DisbursementFormValues } from '../../app/models/disbursement';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';
import LoadingComponent from '../layout/LoadingComponent';
import { Button, Card, Header, Segment } from 'semantic-ui-react';
import { Formik, Form } from 'formik';
import DisbursementInformation from './DisbursementInformation';

export default observer(function Disbursement() {
    const { disbursementStore } = useStore();
    const { createDisbursement, updateDisbursement, loadDisbursement, loadingInitial } = disbursementStore;
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [disbursement, setDisbursement] = useState<DisbursementFormValues>(new DisbursementFormValues());

    useEffect(() => {
        if (id) {
            loadDisbursement(id).then(sDisbursement => setDisbursement(new DisbursementFormValues(sDisbursement)))
        }
    }, [id, loadDisbursement]);

    function handleFormSubmit(d: DisbursementFormValues) {
        console.log("disbursement", d);
        if (!d.id) {
            console.log('no id')
            const newId = uuid();
            const newDisbursement = {
                ...d,
                id: newId
            };
            createDisbursement(newDisbursement).then(() => navigate(`/disbursements/${newId}`));
        } else {
            updateDisbursement(d).then(() => navigate(`/disbursements/${d.id}`))
        }
    }

    const handleCoordinatesChange = (newCoordinateX, newCoordinateY) => {
        setDisbursement({
            ...disbursement,
            coordinateX: newCoordinateX,
            coordinateY: newCoordinateY,
        });
    };

    if (loadingInitial)
        return <LoadingComponent content='Loading disbursement...' />
    return (
        <Segment clearing style={{ height: "1290px", backgroundColor: "transparent", border: "0px " }}>
            <Header content='Disbursement Details' sub color='teal' />
            <Formik
                enableReinitialize
                initialValues={disbursement}
                validationSchema={
                    Yup.object({
                        //amountOfPayment: Yup.number().required('Amount of payment required'),
                        oblastId: Yup.number().required('Oblast required'),
                        spNameId: Yup.number().required('Sub project required'),
                        contractor: Yup.string().required('Contractor required'),
                        month: Yup.date().required('Month of certificate required')
                    })
                }
                onSubmit={(values) => {
                    // Remove commas from the 'value' and 'amountOfPayment' fields before submitting
                    const cleanedValues = {
                        ...values,
                        amountOfPayment: values.amountOfPayment ? Number(values.amountOfPayment.toString().replace(/,/g, '')) : null,
                        value: values.value ? Number(values.value.toString().replace(/,/g, '')) : null
                    };
                    handleFormSubmit(cleanedValues);
                }}>

                {formikProps => {
                    // Destructure the Formik props you want to pass down
                    const { handleSubmit, isValid, isSubmitting, dirty } = formikProps;
                    return (
                        <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                            <Card fluid key='disbursementCard'  >
                                <Card.Content style={{ color: 'teal' }}>
                                    <h1>{id ? 'Edit Disbursement' : 'Add Disbursement'}</h1>
                                </Card.Content>
                                <Card.Content extra key="GeneralInfo">
                                    <DisbursementInformation
                                        disbursement={disbursement}
                                        readOnly={false}
                                        formikProps={formikProps}
                                    />
                                </Card.Content>
                                <Card.Content extra className="main-button-controls" key="SubmitButtons">
                                    <Button
                                        disabled={isSubmitting || !isValid || !dirty}
                                        loading={isSubmitting}
                                        positive type='submit'
                                        content='Submit'
                                        icon="check"
                                        style={{ background: "green", color: "#fff", border: '1px solid green' }}
                                    >
                                    </Button>
                                    <Button
                                        as={Link}
                                        to='/disbursements'
                                        floated='right'
                                        type='button'
                                        content='Cancel'
                                        basic
                                        color='red'
                                    />
                                </Card.Content>
                            </Card>
                        </Form>
                    );
                }}
            </Formik>
        </Segment>
    );
});
