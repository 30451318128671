import React from 'react';
import ReadonlyDialogBox from "./ReadonlyDialogBox";


export default function ReadonlyCardForm() {

    return (
        <div>
            Card form...
            <ReadonlyDialogBox />
        </div>
              
    )
}