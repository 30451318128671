import React, { useState } from "react";
import { Query } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import { observer } from "mobx-react-lite";
import LoadingComponent from "../../../layout/LoadingComponent";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

const tableStyle: React.CSSProperties = {
    width: "100%",
    borderCollapse: "collapse",
};

const thStyle: React.CSSProperties = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
    width: "30%",
};

const tdStyle: React.CSSProperties = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
    width: "70%",
    whiteSpace: "normal",
};

const alternatingRowStyles: React.CSSProperties[] = [
    { backgroundColor: "#f2f2f2" },
    { backgroundColor: "white" },
];

export default observer(function ReportQualityProgressDetails({
    sirId,
}: {
    sirId: string;
}) {
    const [currentRecordIndex, setCurrentRecordIndex] = useState(0);

    const query: Query = {
        dimensions: [
            "VwSiteInspectionReportDetails.qpid",
            "VwSiteInspectionReportDetails.qpissuetype",
            "VwSiteInspectionReportDetails.qpcomments",
            "VwSiteInspectionReportDetails.qpremarks",
            "VwSiteInspectionReportDetails.qpphoto",
        ],
        order: {
            "VwSiteInspectionReportDetails.actblock": "asc",
        },
        filters: [
            {
                dimension: "VwSiteInspectionReportDetails.id",
                operator: "equals",
                values: [sirId ? sirId : "00000000-0000-0000-0000-000000000000"],
            },
            {
                dimension: "VwSiteInspectionReportDetails.qpid",
                operator: "set",
            },
        ],
    };

    const { resultSet, isLoading, error } = useCubeQuery(query);

    // Check for errors or missing sirId
    if (!sirId || error) {
        return (
            <div>
                <div>
                    <b>Please select a Report</b>
                </div>
                <div>
                </div>

            </div>
        );
    }

    if (isLoading || !resultSet) {
        return <LoadingComponent content="Loading..." />;
    }

    const totalRecords = resultSet.tablePivot().length;

    const qpissuetype = resultSet
        .tablePivot()
        .map((row) => row["VwSiteInspectionReportDetails.qpissuetype"])[
        currentRecordIndex
    ];
    const qpcomments = resultSet
        .tablePivot()
        .map(
            (row) =>
                row["VwSiteInspectionReportDetails.qpcomments"]
        )[currentRecordIndex];
    const qpremarks = resultSet
        .tablePivot()
        .map((row) => row["VwSiteInspectionReportDetails.qpremarks"])[
        currentRecordIndex
    ];
    const qpphoto = resultSet
        .tablePivot()
        .map((row) => row["VwSiteInspectionReportDetails.qpphoto"])[
        currentRecordIndex
    ];

    const defaultImageSource = "/assets/not-found-icon-26.jpg";

    const navigateToPreviousRecord = () => {
        if (currentRecordIndex > 0) {
            setCurrentRecordIndex(currentRecordIndex - 1);
        }
    };

    const navigateToNextRecord = () => {
        if (currentRecordIndex < totalRecords - 1) {
            setCurrentRecordIndex(currentRecordIndex + 1);
        }
    };

    const currentRecordNumber = currentRecordIndex + 1;

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button onClick={navigateToPreviousRecord}>
                    <KeyboardArrowLeft /> {/* Left arrow icon */}
                </button>
                <div>
                    {currentRecordNumber} of {totalRecords}
                </div>
                <button onClick={navigateToNextRecord}>
                    <KeyboardArrowRight /> {/* Right arrow icon */}
                </button>
            </div>
            <div>
                <b>Progress/Quality</b>
            </div>
            <table style={tableStyle}>
                <tbody>
                    <tr style={alternatingRowStyles[0]}>
                        <th style={thStyle}>Issue Type</th>
                        <td style={tdStyle}>{qpissuetype}</td>
                    </tr>
                    <tr style={alternatingRowStyles[1]}>
                        <th style={thStyle}>Comments</th>
                        <td style={tdStyle}>{qpcomments}</td>
                    </tr>
                    <tr style={alternatingRowStyles[0]}>
                        <th style={thStyle}>Remarks</th>
                        <td style={tdStyle}>{qpremarks}</td>
                    </tr>
                </tbody>
            </table>
            {qpphoto && (
                <div>
                    <img
                        src={qpphoto.toString()}
                        alt="Report Photo"
                        style={{ maxWidth: "100%", width: "300px", height: "200px" }}
                    />
                </div>
            )}
        </div>
    );
});
