import { observer } from 'mobx-react-lite';
import { useEffect, useState } from "react";
import { MapContainer, TileLayer, LayersControl, Popup, Marker, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import "leaflet-fullscreen/dist/Leaflet.fullscreen.js";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import { geosearch } from "esri-leaflet-geocoder";
import "esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css";
import { Grid, Form } from "semantic-ui-react";
import SirTextInput from '../../app/common/SirTextInput';
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import { Icon } from 'leaflet'

const icon = new Icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: markerIconPng,
    shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});


function AppMap({ data, handleCoordinateChanges }) {
    let { coordinateX, coordinateY, mapReadonly } = data

    const [getCoordX, setCoordX] = useState(Number(coordinateX));
    const [getCoordY, setCoordY] = useState(Number(coordinateY));
    const [map, setMap] = useState(null);

    useEffect(() => {
        setCoordX(Number(coordinateX));
        setCoordY(Number(coordinateY))
    }, [coordinateX, coordinateY]);

    useEffect(() => {
        if (coordinateX && coordinateY && map) {
            L.marker([Number(coordinateX), Number(coordinateY)], { icon }).addTo(map);
        }
    }, [coordinateX, coordinateY, map]);

    useEffect(() => {
        if (map && data.coordinateX && data.coordinateY) {
            const newCoordinates = [data.coordinateY, data.coordinateX];

            // Remove existing markers if any
            map.eachLayer((layer) => {
                if (layer instanceof L.Marker) {
                    map.removeLayer(layer);
                }
            });

            // Add new marker
            L.marker(newCoordinates, { icon }).addTo(map);

            // Center the map on the new marker
            map.setView(newCoordinates, map.getZoom());
        }
    }, [data, data.coordinateX, data.coordinateY, map]);

    const position = [48.3794, 36.2304];
    const { BaseLayer } = LayersControl;

    function LocationMarker(coordinates) {
        const [position, setPosition] = useState(null)
        const map = useMapEvents({
            click(e) {
                if (mapReadonly) {
                    return;
                }
                //map.locate()
                setPosition(e.latlng)
                data.coordinateY = Number(e.latlng.lat);
                data.coordinateX = Number(e.latlng.lng);
                setCoordY(Number(e.latlng.lat));
                setCoordX(Number(e.latlng.lng));
                handleCoordinateChanges(data);
                //console.log('data from click', data);

                map.eachLayer((layer) => {
                    if (layer['_latlng'] !== undefined)
                        layer.remove();
                });

                L.marker([e.latlng.lat, e.latlng.lng], { icon }).addTo(map);
            },
            locationfound(e) {
                if (coordinates) {
                    e.latlng.lat = coordinates.coordinateX;
                    e.latlng.lng = coordinates.coordinateY;
                    setPosition(e.latlng)
                    map.flyTo(e.latlng, map.getZoom())
                    map.eachLayer((layer) => {
                        if (layer['_latlng'] !== undefined)
                            layer.remove();
                    });

                    L.marker([e.latlng.lat, e.latlng.lng], { icon }).addTo(map);
                }
            }
        });

        return position === null ? null : (
            <Marker position={position}>
                <Popup>Report position</Popup>
            </Marker>
        )
    }

    useEffect(() => {
        if (!map) return;
        const control = geosearch();
        control.addTo(map);
    }, [map]);

    //if (loadingInitial) return <LoadingComponent content='Loading map...' />
    return (
        <Grid columns={3} stackable>
            <Grid.Row>
                <Grid.Column width={16}>
                    <MapContainer
                        center={position}
                        fullscreenControl={true}
                        zoom={6}
                        style={{ zIndex: 0, height: "40vh" }}
                        whenCreated={setMap}
                        scrollWheelZoom={true}

                    >
                        <LayersControl position="topright">
                            <BaseLayer checked name="OpenStreetMap">
                                <TileLayer
                                    //attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"

                                />
                            </BaseLayer>
                            <BaseLayer name="Satelite View">
                                <TileLayer
                                    attribution="Satellite View"
                                    url="https://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                                />
                            </BaseLayer>
                            {(coordinateX && coordinateY) ? (

                                <Marker position={[coordinateY, coordinateX]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}>
                                </Marker>
                            ) : (<></>)}
                        </ LayersControl>
                        <LocationMarker coordinates={data} />
                    </MapContainer>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Form.Field>
                        <SirTextInput
                            name='coordinateX'
                            placeholder='longitude'
                            label='Longitude' />
                    </Form.Field>
                </Grid.Column>
                <Grid.Column>
                    <Form.Field>
                        <SirTextInput
                            name='coordinateY'
                            placeholder='Latitude'
                            label='Latitude'
                        />
                    </Form.Field>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default observer(AppMap);
