import { useField } from 'formik';
import React from 'react';
import { Form, Label, Select } from 'semantic-ui-react';

interface Props {
    placeholder: string;
    name: string;
    options: any;
    onChange?: (value: any) => void;
    label?: string;
    disabled?: boolean;
    value?: "";
}

export default function SirSelectInput(props: Props) {
    const [field, meta, helpers] = useField(props.name);
    return (
        <Form.Field error={meta.touched && !!meta.error}>
            <label>{props.label}</label>
            <Select
                clearable
                id={field.value}
                options={props.options}
                value={field.value || ""}
                disabled={props.disabled ? props.disabled : false }
                onChange={(e, d) => {
                    helpers.setValue(d.value);
                    if (props.onChange) {
                        props.onChange(d.value);
                    }
                }
                }
                onBlur={() => helpers.setTouched(true)}
                placeholder={props.placeholder}
            />
            {meta.touched && meta.error ? (
                <Label basic color='red'>{meta.error}</Label>
            ) : null}
        </Form.Field>
    )
}