import { Oblast } from "./oblast";
import { SpName } from "./spname";

export interface Disbursement {
    id: string;
    oblastId: number | null;
    oblast: Oblast | null;
    oblastString: string | null;
    spNameId: number | null;
    spNameString: string | null;
    spName: SpName | null;
    spCode: string | null;
    contractor: string | null;
    contractSigningDate: Date | null;
    contractEndingDate: Date | null;
    month: Date | null;
    contractPrice: number | null;
    amountOfPayment: number | null;
    percentagePaid: number | null;
    coordinateX: number | null;
    coordinateY: number | null;
    amountEur: number | null;
    amountUah: number | null;
}

export class Disbursement implements Disbursement {
    constructor(init?: DisbursementFormValues) {
        Object.assign(this, init);
    }
}

export class DisbursementFormValues {
    id?: string = undefined;
    oblastId: number | null = null;
    oblast?: Oblast | null = null;
    oblastString?: string | null = null;
    spNameId: number | null = null;
    spName?: SpName | null = null;
    spNameString?: string | null = null;
    spCode?: string | null = '';
    contractor: string | null = '';
    contractSigningDate?: Date | null = undefined;
    contractEndingDate?: Date | null = undefined;
    value?: number | null = null;
    month?: Date | null = undefined;
    contractPrice?: number | null = null;
    amountOfPayment?: number | null = null;
    percentagePaid?: number | null = null;
    coordinateX?: number | any | null = null;
    coordinateY?: number | any | null = null;
    amountEur?: number | null = null;
    amountUah?: number | null = null;

    constructor(disbursement?: DisbursementFormValues) {
        if (disbursement) {
            this.id = disbursement.id;
            this.oblastId = disbursement.oblastId;
            this.oblast = disbursement.oblast;
            this.oblastString = disbursement.oblastString;
            this.spNameId = disbursement.spNameId;
            this.spName = disbursement.spName;
            this.spNameString = disbursement.spNameString;
            this.spCode = disbursement.spCode;
            this.contractor = disbursement.contractor;
            this.contractSigningDate = disbursement.contractSigningDate;
            this.contractEndingDate = disbursement.contractEndingDate;
            this.value = disbursement.value;
            this.month = disbursement.month;
            this.contractPrice = disbursement.contractPrice;
            this.amountOfPayment = disbursement.amountOfPayment;
            this.percentagePaid = disbursement.percentagePaid;
            this.coordinateX = disbursement.coordinateX;
            this.coordinateY = disbursement.coordinateY;
            this.amountEur = disbursement.amountEur;
            this.amountUah = disbursement.amountUah;
        }
    }
}
