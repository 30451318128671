import React, { useState } from 'react';
import { Tab } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import ReportOngoingActivitiesDetails from './ReportOngoingActivitiesDetails';
import ReportQualityProgressDetails from './ReportQualityProgressDetails';
import ReportObservanceDetails from './ReportObservanceDetails';
import ReportQualityProgressChartDetails from './ReportQualityProgressChartDetails';

export default observer(function ReportDetails({
    sirId,
}: {
    sirId: string;
}) {
    const [activeTab, setActiveTab] = useState('ongoingActivities');

    const panes = [
        {
            menuItem: { content: 'Ongoing Activities', onClick: () => setActiveTab('ongoingActivities') },
            render: () => (
                <Tab.Pane key='paneReportOngoingActivities'>
                    <ReportOngoingActivitiesDetails sirId={sirId} />
                </Tab.Pane>
            ),
            key: 'ongoingActivities',
        },
        {
            menuItem: { content: 'Progress/Quality', onClick: () => setActiveTab('progressQuality') },
            render: () => (
                <Tab.Pane key='paneReportQualityProgress'>
                    <ReportQualityProgressDetails sirId={sirId} />
                </Tab.Pane>
            ),
            key: 'progressQuality',
        },
        {
            menuItem: { content: 'Observance', onClick: () => setActiveTab('observance') },
            render: () => (
                <Tab.Pane key='paneReportObsevanceDetails'>
                    <ReportObservanceDetails sirId={sirId} />
                </Tab.Pane>
            ),
            key: 'observance',
        },
    ];

    return (
        <div>
            <Tab key='allpanes'
                panes={panes}
                activeIndex={
                    activeTab
                        ? panes.findIndex((pane) => pane.key === activeTab)
                        : undefined
                }
                menu={{ secondary: true, pointing: true, attached: false }}
            />
            <div>
                <ReportQualityProgressChartDetails sirId={sirId} />
            </div>
        </div>
    );
});
