import React from "react";
import { FormField, Grid } from "semantic-ui-react";
import SirDateInput from "../app/common/SirDateInput";
import SirTextInput from "../app/common/SirTextInput";
import { SiteInspectionReportFormValues } from "../app/models/siteinspectionreport";
import "./Progress.css";

const Progress = ({ report, readOnly = false }: { report: SiteInspectionReportFormValues, readOnly: boolean }) => {
    return (
        <Grid columns={2} stackable key='ProgressGrid'>
            <Grid.Row>
                <Grid.Column>
                    <FormField>
                        <SirTextInput type="number" name='percentCompleted' placeholder='Actual % completed' label='Actual % completed' disabled={readOnly} />
                    </FormField>
                </Grid.Column>
                <Grid.Column>
                    <FormField>
                        <label>Planned Completion Date</label>
                        <SirDateInput name='plannedCompletionDate'
                            nextYearAriaLabel="Next Year"
                            placeholderText='Planned Completion Date'
                            showYearDropdown
                            disabled={readOnly}
                        //dateFormat='MMMM d, yyyy'
                        />
                    </FormField>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default Progress;
