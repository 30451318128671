import React from 'react'
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useStore } from "../stores/store";
import { toast } from 'react-toastify';

const RequireAuth = ({ roles }: { roles?: string[] }) => {
    const { userStore: { isLoggedIn, getUserRoles } } = useStore();
    const location = useLocation();

    if (!isLoggedIn) {
        toast.error("Not logged in!");
        return <Navigate to='/' state={{ from: location }} />
    }
    if (!(roles && getUserRoles.some(userRole => roles.includes(userRole)))) {
        toast.error("User not authorized!");
        return <Navigate to='/' state={{ from: location }} />
    }
    return <Outlet />
}

export default RequireAuth;