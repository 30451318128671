import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { SiteInspectionReport, SiteInspectionReportFormValues } from '../models/siteinspectionreport';
import agent from "../api/agent";
import { Pagination, PagingParams } from "../models/pagination";
import { format } from 'date-fns';
import { toast } from 'react-toastify';


export default class SiteInspectionReportStore {
    title = "Site Inspection Reports";
    selectedSiteInspectionReport: SiteInspectionReport | undefined = undefined;
    siteInspectionReportRegistry = new Map<string, SiteInspectionReport>();
    editMode = false;
    loading = false;
    loadingInitial = false;
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();
    predicate = new Map().set('all', true);

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.predicate.keys(),
            () => {
                this.siteInspectionReportRegistry.clear();
                this.pagingParams = new PagingParams();
                this.loadSiteInspectionReports();
            }
        )
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    setPredicate = (predicate: string, value: string | Date | number | boolean) => {
        const resetPredicate = () => {
            this.predicate.forEach((value, key) => {
                this.predicate.delete(key);
            })
        }
        switch (predicate) {
            case 'all':
                resetPredicate();
                this.predicate.set('all', true);
                break;
            case 'oblastId':
                this.predicate.delete('oblastId');
                this.predicate.set('oblastId', value);
                break;
            case 'inspectionStartDate':
                this.predicate.delete('inspectionStartDate');
                this.predicate.set('inspectionStartDate', value);
                break;
            case 'inspectionEndDate':
                this.predicate.delete('inspectionEndDate');
                this.predicate.set('inspectionEndDate', value);
                break;
            case 'isFinal':
                this.predicate.delete('isFinal');
                this.predicate.set('isFinal', value);
                break;
        }
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());
        this.predicate.forEach((value, key) => {
            if (key === 'startDate') {
                params.append(key, (value as Date).toISOString())
            } else {
                params.append(key, value);
            }
        })
        return params;
    }

    createSiteInspectionReport = async (siteInspectionReport: SiteInspectionReportFormValues) => {
        try {
            await agent.SiteInspectionReports.create(siteInspectionReport);
            const newReport = new SiteInspectionReport(siteInspectionReport);
            //newReport
            this.setSiteInspectionReport(newReport);
            runInAction(() => {
                this.selectedSiteInspectionReport = newReport;
            })
            toast.success('Site inspection report created');
        }
        catch (error) {
            console.log(error);
            toast.error('An error occurred creating report')
        }
    }

    updateSiteInspectionReport = async (siteInspectionReport: SiteInspectionReportFormValues) => {
        await agent.SiteInspectionReports.update(siteInspectionReport);
        runInAction(() => {
            if (siteInspectionReport.id) {
                let updatedReport = { ...this.getSiteInspectionReport(siteInspectionReport.id), ...siteInspectionReport };
                this.siteInspectionReportRegistry.set(siteInspectionReport.id, updatedReport as SiteInspectionReport);
                this.selectedSiteInspectionReport = updatedReport as SiteInspectionReport;
            }
        })
    }

    loadSiteInspectionReports = async () => {
        this.loadingInitial = true;
        try {
            const result = await agent.SiteInspectionReports.list(this.axiosParams);
            result.data.forEach(report => {
                this.setSiteInspectionReport(report);
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
        }
        catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
        }
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    loadSiteInspectionReport = async (id: string) => {
        let report = this.getSiteInspectionReport(id);
        //if (report) {
        //   this.selectedSiteInspectionReport = report;
        //    return report;
        //} else {
        this.loadingInitial = true;
        try {
            report = await agent.SiteInspectionReports.details(id);
            this.setSiteInspectionReport(report);
            runInAction(() => {
                this.selectedSiteInspectionReport = report;
            })
            this.setLoadingInitial(false);
            return report;
        } catch (error) {
            console.log(error);
            toast.error('An error occurred loading report!')
            this.setLoadingInitial(false);
        }
        //    }
    }

    downloadSiteInspectionReport = async (id: string) => {
        this.loadingInitial = true;
        try {
            const result = await agent.SiteInspectionReports.download(id);
            this.setLoadingInitial(false);
            return result;
        }
        catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
        }
    }

    get reportsByDate() {
        return Array.from(this.siteInspectionReportRegistry.values()).sort((a, b) =>
            new Date(a.inspectionDate!).getTime() - new Date(b.inspectionDate!).getTime()).reverse();
    }

    get groupedReports() {
        return Object.entries(
            this.reportsByDate.reduce((reports, report) => {
                const date = format(new Date(report.inspectionDate!), 'MMM yyyy');
                reports[date] = reports[date] ? [...reports[date], report] : [report];
                return reports;
            }, {} as { [key: string]: SiteInspectionReport[] })
        )
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    private setSiteInspectionReport = (report: SiteInspectionReport) => {
        this.siteInspectionReportRegistry.set(report.id, report);
    }

    private getSiteInspectionReport = (id: string) => {
        return this.siteInspectionReportRegistry.get(id);
    }

    clearSelectedSiteInspectionReport = () => {
        this.selectedSiteInspectionReport = undefined;
    }
}