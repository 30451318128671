import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import DisbursementStore from "./disbursementStore";
import ModalStore from "./modalStore";
import ProfileStore from "./profileStore";
import RefDataStore from "./refDataStore";
import SiteInspectionReportStore from "./siteInspectionReportStore";
import UserStore from "./userStore";

interface Store {
    siteInspectionReportStore: SiteInspectionReportStore;
    commonStore: CommonStore
    userStore: UserStore;
    modalStore: ModalStore;
    refDataStore: RefDataStore;
    profileStore: ProfileStore;
    disbursementStore: DisbursementStore;
}

export const store: Store = {
    siteInspectionReportStore: new SiteInspectionReportStore(),
    commonStore: new CommonStore(),
    userStore: new UserStore(),
    modalStore: new ModalStore(),
    refDataStore: new RefDataStore(),
    profileStore: new ProfileStore(),
    disbursementStore: new DisbursementStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}
