//import React from "react";
//import './FooterStyle.css';

//const Footer() => {
//    return (
//        <section className="footer">

//            <section className="footer-info">
//                <section className="footer-info-left">
//                    <section className="footer-info__image">
//                        <a href="https://usif.ua/en/" target="_blank"><img src="/assets/footer-logo.png" alt='logo' /></a>  
//                    </section>
//                </section>
//                <section className="footer-info-right">
//                    <section className="footer-info__number">
//                        Copyright USIF 2023
//                        All rights reserved
//                    </section>
//                </section>
//            </section>

//        </section>
//    )
//}

//export default Footer;



import React from 'react';
import './FooterBanner.css';

function FooterBanner() {
    return (
        <div className="footer-container">
            <div className="footer-content">
                <a href="https://usif.ua/en/" target="_blank" rel="noopener noreferrer"><img src="/assets/footer-logo.png" alt="USIF logo" /></a>
                <p>Copyright USIF 2023<br />
                    All rights reserved</p>
            </div>
        </div>
    );
}

export default FooterBanner;