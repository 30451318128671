import React from 'react'
import { format } from 'date-fns';
import { Button, Card, CardMeta, Grid, Icon, Image } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { SiteInspectionReport } from '../../app/models/siteinspectionreport'
import { store } from '../../app/stores/store';
import { Tooltip } from 'react-tooltip';

const ReportListItem = ({ report }: { report: SiteInspectionReport }) => {
    const { spNameString, oblastString, id, inspectionDate, isFinal } = report

    const userRoles = store.userStore.getUserRoles;
    const isUsifViewer = userRoles.includes('USIFV');
    //console.log("isUsifViewer", isUsifViewer);
    //console.log("roles", userRoles);

    const onButtonClick = () => {
        store.siteInspectionReportStore.downloadSiteInspectionReport(report.id!).then(response => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            let dateString = report.inspectionDate ? format(new Date(report.inspectionDate), "ddMMyyyy") : "";
            link.setAttribute('download', "SIR-USIF_" + report.spNameString + "_" + dateString + ".pdf");
            document.body.appendChild(link);
            link.click();
        })
            .catch(error => {
                console.error(error);
            });
    }
    return (
        <Card fluid>
            <Card.Content>
                <Grid columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column>
                            <Image floated='left' size='mini' src={'/assets/m4h-logo-web-small.png'} />
                            <Card.Header>
                                {oblastString}
                            </Card.Header>
                            {inspectionDate ? (
                                <Card.Meta>
                                    {spNameString} - {format(new Date(inspectionDate), "dd MMM yyyy")}
                                </Card.Meta>
                            ) : (
                                <CardMeta>{spNameString}</CardMeta>)}
                        </Grid.Column>
                        <Grid.Column>
                            <Grid.Row>
                                {isFinal ? (
                                    <>
                                        <Button
                                            as={Link}
                                            to={`/reports/${id}`}
                                            icon
                                            color='yellow'
                                            size='mini'
                                            title='View'
                                            data-tip='View'
                                        >
                                            <Icon name='eye' />
                                        </Button>
                                        <Tooltip />
                                    </>
                                ) : !isUsifViewer && (
                                    <>
                                        <Button
                                            as={Link}
                                            to={`/manage/${id}`}
                                            icon
                                            color='teal'
                                            size='mini'
                                            title='Edit'
                                            data-tip='Edit'
                                        >
                                            <Icon name='edit' />
                                        </Button>
                                        <Tooltip />
                                    </>
                                )}
                                {isUsifViewer && (
                                    <Button
                                        as={Link}
                                        to={`/reports/${id}`}
                                        icon
                                        color='blue'
                                        size='mini'
                                        title='View Draft'
                                        data-tip='View Draft'
                                    >
                                        <Icon name='eye' />
                                    </Button>
                                )}
                                <Button
                                    onClick={onButtonClick}
                                    icon
                                    color='green'
                                    size='mini'
                                    title='Download Report'
                                    data-tip='Download Report'
                                >
                                    <Icon name='download' />
                                </Button>
                                <Tooltip />
                            </Grid.Row>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Card.Content>
        </Card>
    )
}

export default ReportListItem
