import React, { Fragment } from 'react';
import { Button, Placeholder, Card, Grid, Icon } from 'semantic-ui-react';

export default function ReportListItemPlaceholder() {
    return (

        <Card fluid>
            <Card.Content>
                <Grid columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column>
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Grid.Column>
                        <Grid.Column>
                            <Button
                                disabled
                                icon
                                color='yellow'
                                size='mini'
                            >
                                <Icon name='eye' />
                            </Button>
                            <Button
                                disabled
                                icon
                                color='teal'
                                size='mini'
                            >
                                <Icon name='edit' />
                            </Button>
                            <Button
                                disabled
                                icon
                                color='green'
                                size='mini'
                            >
                                <Icon name='download' />
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Card.Content >
        </Card >

        //    <Fragment>
        //        <Placeholder fluid style={{ marginTop: 25 }}>
        //            <Segment.Group>
        //                <Segment style={{ minHeight: 110 }}>
        //                    <Placeholder>
        //                        <Placeholder.Header image>
        //                            <Placeholder.Line />
        //                        </Placeholder.Header>
        //                        <Placeholder.Paragraph>
        //                            <Placeholder.Line />
        //                        </Placeholder.Paragraph>
        //                    </Placeholder>
        //                </Segment>
        //                <Segment>
        //                    <Placeholder>
        //                        <Placeholder.Line />
        //                    </Placeholder>
        //                </Segment>
        //                <Segment.Group horizontal style={{ padding: "10px", backgroundColor: "white" }}>
        //                    <Segment floated='left' secondary style={{ minHeight: 70 }} />
        //                    <Segment floated='right' textAlign='center' >
        //                        <Button disabled
        //                            style={{ backgroundColor: "transparent", color: "#E8871E", border: '1px solid #E8871E' }}
        //                            color='teal'
        //                            content='View'
        //                        />
        //                        <Button disabled
        //                            style={{ backgroundColor: "#15616D" }}
        //                            color='teal'
        //                            content='Download'
        //                        />
        //                    </Segment>
        //                </Segment.Group>
        //            </Segment.Group>
        //        </Placeholder>
        //    </Fragment>
    );
};