import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller';
import { Grid, Loader } from 'semantic-ui-react'
import { PagingParams } from '../../app/models/pagination';
import { useStore } from '../../app/stores/store';
import DisbursementList from './DisbursementList';
import DisbursementListItemPlaceholder from './DisbursementListItemPlaceHolder';
import DisbursementsFilters from './DisbursementsFilters';

export default observer(function DisbursementsDashboard() {
    const { disbursementStore } = useStore();
    const { loadDisbursements, disbursementRegistry, setPagingParams, pagination } = disbursementStore;
    const [loadingNext, setLoadingNext] = useState(false);

    function handleGetNext() {
        setLoadingNext(true);
        setPagingParams(new PagingParams(pagination!.currentPage + 1))
        loadDisbursements().then(() => setLoadingNext(false));
    }

    useEffect(() => {
        if (disbursementRegistry.size <= 1) loadDisbursements();
    }, [disbursementRegistry.size, loadDisbursements])


    return (
        <Grid style={{ border: "none" }}>
            <Grid.Column width='10'>
                {disbursementStore.loadingInitial && !loadingNext ? (
                    <>
                        <DisbursementListItemPlaceholder />
                        <DisbursementListItemPlaceholder />
                        <DisbursementListItemPlaceholder />
                        <DisbursementListItemPlaceholder />
                    </>
                ) : (
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={handleGetNext}
                        hasMore={!loadingNext && !!pagination && pagination.currentPage < pagination.totalPages}
                        initialLoad={false}
                    >
                        <DisbursementList />
                    </InfiniteScroll>
                )}
            </Grid.Column>
            <Grid.Column width='6'>
                <DisbursementsFilters />
            </Grid.Column>
            <Grid.Column width={10}>
                <Loader active={loadingNext} />
            </Grid.Column>
        </Grid>

    )
});
