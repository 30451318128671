import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { useStore } from "../../app/stores/store";
import * as Yup from 'yup';
import SirTextInput from '../../app/common/SirTextInput';
import ValidationError from "../errors/ValidationErrors";
import { ErrorMessage, Form, Formik } from "formik";
import { Button } from 'semantic-ui-react';


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default observer(function ResetPasswordForm() {
    const { userStore } = useStore();
    const query = useQuery();
    const encodedToken = query.get('token') || '';
    return (
        <Formik
            initialValues={{ newpassword: '', confirmpassword: '', token: encodedToken, error: null }}
            onSubmit={(values, { setErrors }) =>
                userStore.resetPassword(values).catch(error => setErrors({ error: error }))}
            validationSchema={Yup.object({
                newpassword: Yup.string().required('Password must not be empty'),
                confirmpassword: Yup.string().oneOf([Yup.ref('newpassword'), null], "Passwords don't match!").required('Password must not be empty')
            })}
       >
            {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
                <Form className='ui form error' onSubmit={handleSubmit} autoComplete='off'>
                    <SirTextInput placeholder="Password" name='newpassword' type='password' />
                    <SirTextInput placeholder="Confirm Password" name='confirmpassword' type='password' />
                    <ErrorMessage name='error' render={() =>
                        <ValidationError errors={errors.error} />} />
                    <Button
                        disabled={!isValid || !dirty || isSubmitting}
                        loading={isSubmitting}
                        positive content='Reset Password'
                        type="submit" fluid
                    />
                </Form>
            )}
        </Formik>
    );
});
