import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material/';
import { Grid, TextField, FormControl, RadioGroup, FormLabel, Radio, FormControlLabel, IconButton, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { OngoingActivity } from '../../app/models/ongoingactivity';

export default function ProgresDialogBox({ open, handleClose, data }) {

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Create new Progress
                </DialogTitle>
                <DialogContent>
                    <Form>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField
                                    id="comments"
                                    name="comments"
                                    variant="outlined"
                                    label="Comments"
                                    placeholder="Add comments" 
                                    multiline
                                    minRows={2}
                                    maxRows={4}
                                />
                                <TextField
                                    id="percentage"
                                    name="percentage"  
                                    variant="outlined"
                                    label="Percentage"
                                    placeholder="Add percentage"
                                    multiline
                                    minRows={2}
                                    maxRows={6}
                                />
                                <input
                                    accept="image/*"
                                    id="icon-button-photo"
                                    //name={fileName}
                                   
                                    type="file"
                                />
                            </Grid>
                        </Grid>
                    </Form>
                </DialogContent>
                <DialogActions>


                    <Button onClick={handleClose} color="secondary" variant="outlined">Cancel</Button>


                    <Button color="primary" variant="outlined">Submit</Button>


                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiFormControl-root": {
            width: "90%",
            margin: theme.spacing(1),
        },
    },
}));

export function Form(props) {
    const classes = useStyles();
    return <form className={classes.root}>{props.children}</form>;
}
