import { observer } from 'mobx-react-lite';
import React from 'react';
import { Tab } from 'semantic-ui-react';
import { Profile } from '../../app/models/profile';
import ProfileAbout from './ProfileAbout';
import ProfilePassword from './ProfilePassword';
interface Props {
    profile: Profile;
}
export default observer(function ProfileContent({ profile }: Props) {
    const panes = [
        {
            menuItem: 'About', render: () => <ProfileAbout />
        },
        {
            menuItem: 'Update password', render: () => <ProfilePassword />
        },
    ];
    return (
        <Tab
            menu={{ fluid: true, vertical: true }}
            menuPosition='right'
            panes={panes}
        />
    )
})