import * as React from "react";
import NavBar from "./NavBar";
import { Container } from "semantic-ui-react";
import { ToastContainer } from "react-toastify";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import ModalContainer from "../../app/common/modals/ModalContainer";
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import HomePage from "../home/HomePage";
import Footer from './Footer';
import LoadingComponent from "./LoadingComponent";

function App() {
    const location = useLocation();
    const { commonStore, userStore } = useStore();

    useEffect(() => {
        if (commonStore.token) {
            userStore.getUser().finally(() => commonStore.setAppLoaded())
        } else {
            commonStore.setAppLoaded()
        }
    }, [commonStore, userStore])

    //useEffect(() => {
    //    userStore.initialize();
    //}, [userStore]);

    if (!commonStore.appLoaded) return <LoadingComponent content="Loading app..." />

    return (
        <>
            <div>
                <ScrollRestoration />
                <ModalContainer />
                <ToastContainer position='bottom-right' hideProgressBar theme='colored' />
                {location.pathname === '/' ? <HomePage /> : (
                    <>
                        <NavBar />
                        <Container style={{ marginTop: '7em', marginBottom: '260px', border: "none" }} >
                            <Outlet />
                        </Container>
                    </>
                )}
            </div>
            <div>
                <Footer />
            </div>
        </>
    );
}

export default observer(App);
