import React, { useState, useEffect } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { Button, Header, Modal } from 'semantic-ui-react';
import SirTextInput from '../../app/common/SirTextInput';
import ValidationError from "../errors/ValidationErrors";
import { User } from '../../app/models/user';
import { useStore } from '../../app/stores/store';

interface UpdatePasswordProps {
    user: User | null;
    isOpen: boolean;
    onClose: () => void;
}

export default observer(function UpdatePassword(
    {
        user,
        isOpen,
        onClose
    }: UpdatePasswordProps) {

    const { userStore } = useStore();

    const [formValues, setFormValues] = useState({
        password: '',
        confirmPassword: '',
        error: null
    });

    useEffect(() => {
        if (user) {
            setFormValues({
                password: '',
                confirmPassword: '',
                error: null,
            });
        }
    }, [user]);

    const handleSubmit = async (values: any) => {
        try {
            await userStore.updateOtherUserPassword({ ...user, ...values });
            onClose();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Modal open={isOpen} onClose={onClose} size="tiny">
            <Modal.Header>Password update</Modal.Header>
            <Modal.Content>
                <Formik
                    enableReinitialize
                    initialValues={formValues}
                    onSubmit={handleSubmit}
                    validationSchema={Yup.object({
                        password: Yup.string().required('Password must not be empty'),
                        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], "Passwords don't match!").required('Password must not be empty')
                    })}
                >
                    {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
                        <Form
                            className="ui form error"
                            onSubmit={handleSubmit}
                            autoComplete="off"
                        >
                            <SirTextInput placeholder="Password" name='password' type='password' />
                            <SirTextInput placeholder="Confirm Password" name='confirmPassword' type='password' />
                            <ErrorMessage name='error' render={() =>
                                <ValidationError errors={errors.error} />} />
                            <Button
                                disabled={!isValid || !dirty || isSubmitting}
                                loading={isSubmitting}
                                positive content='Update'
                                type="submit" fluid
                            />
                        </Form>
                    )}
                </Formik>
            </Modal.Content>
        </Modal>
    );
});