import React from 'react';
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Button } from "semantic-ui-react";
import SirTextInput from "../../app/common/SirTextInput";
import { useStore } from "../../app/stores/store";
import * as Yup from 'yup';
interface Props {
    setEditMode: (editMode: boolean) => void;
}
export default observer(function ProfileEditForm({ setEditMode }: Props) {
    const { profileStore: { profile, updateProfile } } = useStore();
    return (
        <Formik
            initialValues={{
                phoneNumber: profile ? profile!.phoneNumber : '',
                email: profile ? profile!.email : ''
            }}
            onSubmit={values => {
                updateProfile(values).then(() => {
                    setEditMode(false);
                })
            }}
            validationSchema={Yup.object({
                phoneNumber: Yup.string().required(),
                email: Yup.string().email().required()
            })}
        >
            {({ isSubmitting, isValid, dirty }) => (
                <Form className='ui form'>
                    <SirTextInput placeholder='Email'
                        name='email' />
                    <SirTextInput placeholder='Phone Number'
                        name='phoneNumber' />
                    <Button
                        positive
                        type='submit'
                        loading={isSubmitting}
                        content='Update profile'
                        floated='right'
                        disabled={!isValid || !dirty}
                    />
                </Form>
            )}
        </Formik>
    )
})