import React from 'react';
import { useField, useFormikContext } from 'formik';
import { Form, Label } from 'semantic-ui-react';
import { NumericFormat } from 'react-number-format';
import { TextField } from '@mui/material';

interface Props {
    placeholder: string;
    name: string;
    type?: string;
    label?: string;
    value?: string;
    disabled?: boolean;
}

export default function SirTextInput(props: Props) {
    //const formikContext = useFormikContext();
    const [field, meta] = useField(props.name);
    const { setFieldValue } = useFormikContext();

    const handleValueChange = (values) => {
        const { value } = values;
        const newValue = value.replace(/,/g, '');
        // Update the field value, removing commas
        setFieldValue(props.name, String(newValue).trim() === '' ? null : newValue, true);
    };

    return (
        <Form.Field error={meta.touched && !!meta.error}>
            <label>{props.label}</label>
            {props.type === 'number' ? (
                <NumericFormat
                    {...field}
                    thousandSeparator=","
                    onValueChange={handleValueChange}
                    valueIsNumericString
                    type="text"
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                    renderText={(value) => <input value={value} readOnly />}
                />
            ) : (
                <TextField {...field} value={field.value || ''} {...props} fullWidth />
            )}
            {meta.touched && meta.error ? (
                <Label basic color='red'>{meta.error}</Label>
            ) : null}
        </Form.Field>
    );
}
