import React, { useState, useEffect } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { observer } from 'mobx-react-lite';
import { Button, Grid, Header, Modal } from 'semantic-ui-react';
import * as Yup from 'yup';
import ValidationError from '../errors/ValidationErrors';
import SirTextInput from '../../app/common/SirTextInput';
import SirSelectInput from '../../app/common/SirSelectInput';
import { useStore } from '../../app/stores/store';
import { SpName } from '../../app/models/spname';
import { Oblast } from '../../app/models/oblast';
import { SpNameStatus } from '../../app/models/spnamestatus';
import AppMap from '../Map/AppMap';

interface EditSpNameProps {
    spName: SpName | null;
    isOpen: boolean;
    onClose: () => void;
}

interface Res {
    text: string;
    value: number;
}

interface FormValues {
    name: string;
    oblastId: number | null;
    spCode: string;
    coordinateX: number | null;
    coordinateY: number | null;
    statusId: number | null;
    contractor: string;
    contractorRepresentative: string;
    usifRepresentative: string;
    error: string | null;
}

export default observer(function EditSpName({
    spName,
    isOpen,
    onClose
}: EditSpNameProps) {
    const { refDataStore } = useStore();

    const [oblast, setOblast] = useState<Oblast[]>([]);
    const [status, setStatus] = useState<SpNameStatus[]>([]);
    const [formValues, setFormValues] = useState<FormValues>({
        name: '',
        oblastId: null,
        spCode: '',
        coordinateX: null,
        coordinateY: null,
        statusId: null,
        contractor: '',
        contractorRepresentative: '',
        usifRepresentative: '',
        error: null
    });

    useEffect(() => {
        if (spName) {
            setFormValues({
                name: spName.name || '',
                oblastId: spName.oblastId,
                spCode: spName.spCode || '',
                coordinateX: spName.coordinateX ?? null,
                coordinateY: spName.coordinateY ?? null,
                statusId: spName.statusId ?? null,
                contractor: spName.contractor || '',
                contractorRepresentative: spName.contractorRepresentative || '',
                usifRepresentative: spName.usifRepresentative || '',
                error: null,
            });
        }
        else {
            // Creating a new spName, set default or empty values.
            setFormValues({
                name: '',
                oblastId: null,
                spCode: '',
                coordinateX: null,
                coordinateY: null,
                statusId: null,
                contractor: '',
                contractorRepresentative: '',
                usifRepresentative: '',
                error: null
            });
        }
    }, [spName]);

    React.useEffect(() => {
        refDataStore.loadOblasts().then((res) => setOblast(res ? res : []))
            .catch((error) => console.log(error));
    }, [refDataStore]);

    React.useEffect(() => {
        refDataStore.loadSpNameStatuses().then((res) => setStatus(res ? res : []))
            .catch((error) => console.log(error));
    }, [refDataStore]);

    let oblasts: Res[] = [];
    let statuses: Res[] = [];

    const mapOblastsToResult = () => {
        oblast.forEach(oblast => {
            let r = {} as Res;
            r.text = oblast.name;
            r.value = oblast.id;
            oblasts.push(r);
        })
    };

    const mapStatusesToResult = () => {
        status.forEach(st => {
            let r = {} as Res;
            r.text = st.name;
            r.value = st.id;
            statuses.push(r);
        })
    };

    mapOblastsToResult();
    mapStatusesToResult();

    const geoData = {
        coordinateX: spName?.coordinateX,
        coordinateY: spName?.coordinateY,
        mapReadonly: false
    };

    const handleSubmit = async (values: any) => {
        try {
            // Check if spName is null to determine create/update
            if (spName) {
                await refDataStore.updateSpName({ ...spName, ...values });
            } else {
                await refDataStore.createSpName(values);
            }
            onClose();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Modal open={isOpen} onClose={onClose} size="small">
            <Modal.Content>
                <Formik
                    enableReinitialize
                    initialValues={formValues}
                    onSubmit={handleSubmit}
                    validationSchema={Yup.object({
                        name: Yup.string().required('Sub project name required'),
                        oblastId: Yup.string().required('Oblast required'),
                        spCode: Yup.string().required('Code required'),
                        statusId: Yup.string().required('Sub project must have a status'),
                    })}
                >
                    {({ handleSubmit, isSubmitting, errors, isValid, dirty, setFieldValue }) => {
                        const handleCoordinateChanges = () => {
                            console.log('geoData', geoData);
                            setFieldValue("coordinateX", geoData.coordinateX ?? null);
                            setFieldValue("coordinateY", geoData.coordinateY ?? null);
                        };
                        return (
                            <Form
                                className="ui form error"
                                onSubmit={handleSubmit}
                                autoComplete="off"
                            >
                                <Header
                                    as="h2"
                                    content={spName ? "Edit Sub project" : "Create Sub project"}
                                    color="teal"
                                    textAlign="center"
                                />
                                <Grid columns={2} stackable>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <SirSelectInput options={oblasts} placeholder='Oblast' name='oblastId' label='Oblast' />
                                            <SirSelectInput options={statuses} placeholder='Sub project status' name='statusId' label='Sub project Status' />
                                            <SirTextInput placeholder="Sub project name" name="name" label="Sub project name" />
                                            <SirTextInput placeholder="Sub project code" name="spCode" label="Sub project code" />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <SirTextInput placeholder="Contractor" name="contractor" label="Contractor" />
                                            <SirTextInput placeholder="Contractor Representative" name="contractorRepresentative" label="Contractor Representative" />
                                            <SirTextInput placeholder="USIF Representative" name="usifRepresentative" label="USIF Representative" />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <AppMap data={geoData} handleCoordinateChanges={handleCoordinateChanges} />
                                <div style={{ marginTop: '20px' }}>
                                    <Button
                                        disabled={!isValid || !dirty || isSubmitting}
                                        loading={isSubmitting}
                                        positive
                                        content="Save"
                                        type="submit"
                                        fluid
                                    />
                                </div>
                                <ErrorMessage name="error" render={() => (
                                    <ValidationError errors={errors.error} />
                                )} />
                            </Form>
                        );
                    }}
                </Formik>
            </Modal.Content>
        </Modal>
    );
});
