import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { FormField, Grid, Select } from 'semantic-ui-react';
import { Oblast } from '../../app/models/oblast';
import { useStore } from '../../app/stores/store';
import { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';



interface Res {
    text: string;
    value: number;
}

export default observer(function ActivityFilters() {


    const { siteInspectionReportStore: { predicate, setPredicate } } = useStore();
    const [getOblast, setOblast] = React.useState<Oblast[]>([]);
    const { refDataStore } = useStore();

    React.useEffect(() => {
        refDataStore.loadOblasts().then((res) => setOblast(res ? res : []))
            .catch((error) => console.log(error));
    }, []);

    let results: Res[] = [];

    const mapOblastToResult = () => {
        getOblast.forEach(oblast => {
            let r = {} as Res;
            r.text = oblast.name;
            r.value = oblast.id;
            results.push(r);
        })
    }
    mapOblastToResult();

    const [inspectionStartDate, setInspectionStartDate] = useState(new Date('2021-06-04'));
    const [inspectionEndDate, setInspectionEndDate] = useState(new Date());
    const onStartDateChange = (date) => {
        setInspectionStartDate(new Date(date));
        setPredicate('inspectionStartDate', new Date(date).toISOString());
    };
    const onEndDateChange = (date) => {
        setInspectionEndDate(new Date(date));
        setPredicate('inspectionEndDate', new Date(date).toISOString());
    };

    const [value, setValue] = React.useState<Dayjs | null>(null);

    return (
        <> 
            <Grid columns={1} key='FilterGrid' style={{ position: "fixed", top: "110px", width: "500px" }}>
                <label style={{ color: "#2364AA", fontSize: "15px"}}>Filter by Oblast</label>
                <Grid.Row columns={1}>
                    <Grid.Column  >
                        <FormControl fullWidth>
                            <Select 
                                style={{ backgroundColor: "#EBF5DF", boxShadow: "2px 2px 2px #888888" }}
                                options={results}
                                clearable
                                search
                                //allowAdditionsMessage
                                onChange={(e, data) => {
                                    setPredicate('oblastId', data.value as number);
                                }} 
                            />
                        </FormControl>
                    </Grid.Column>
                </Grid.Row>
                <label style={{ color: "#2364AA", fontSize: "15px" }}>Filter by Inspection Date</label>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <FormField>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    disableFuture
                                    openTo="year"
                                    views={['year', 'month', 'day']}
                                    label="Start Date"
                                    value={inspectionStartDate}
                                    onChange={(date) => {
                                        onStartDateChange(date);
                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        sx={{ backgroundColor: "#EBF5DF", boxShadow: "2px 2px 2px #888888" }}
                                    />}
                                />
                            </LocalizationProvider>
                        </FormField>
                    </Grid.Column>
                    <Grid.Column>
                        <FormField>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    disableFuture
                                    openTo="year"
                                    views={['year', 'month', 'day']}
                                    label="End Date"
                                    value={inspectionEndDate}
                                    onChange={(date) => onEndDateChange(date)}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        sx={{ backgroundColor: "#EBF5DF", boxShadow: "2px 2px 2px #888888" }}
                                    />}
                                />
                            </LocalizationProvider>
                        </FormField>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    )
})
