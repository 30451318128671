import * as React from 'react';
import { Grid, Button } from '@material-ui/core'
import OngoingActivitiesDialogBox from '../OngoingActivities/OngoingActivitiesDialogBox';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { v4 as uuid } from 'uuid';
import { toJS } from 'mobx'
import { OngoingActivity } from '../../app/models/ongoingactivity';
import { SiteInspectionReportFormValues } from '../../app/models/siteinspectionreport';
import { useRef } from 'react';
import { FormikProps } from 'formik';

import { makeStyles } from "@material-ui/core/styles";
import ConfirmationDialog from '../UI/ConfirmationDialog';

const useStyles = makeStyles({
    root: {
        background: "#278DAD",
        borderRadius: 3,
        border: 0,
        color: "white",
        height: 40,
        padding: "0 30px",
        boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
        '&:hover': {
            backgroundColor: '#D7C333',
            color: '#3c52b2'
        }
    },
    label: {
        textTransform: "capitalize"
    }
});

const arr: OngoingActivity[] = [];

const OngoingActivities = ({
    report,
    readOnly = false,
    formikProps = {} as FormikProps<SiteInspectionReportFormValues> }: {
        report: SiteInspectionReportFormValues,
        readOnly: boolean,
        formikProps: FormikProps<SiteInspectionReportFormValues>
    }) => {
    const classes = useStyles();
    const [editingActivityIndex, setEditingActivityIndex] = React.useState<number | undefined>(undefined);

    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
    const [activityToRemove, setActivityToRemove] = React.useState(null);

    const onRemove = (index) => {
        setActivityToRemove(index);
        setIsConfirmDialogOpen(true);
    };

    const handleConfirmDelete = () => {
        const updatedActivities = formikProps.values.ongoingActivities!.filter((_, i) => i !== activityToRemove);
        formikProps.setFieldValue('ongoingActivities', updatedActivities);

        setIsConfirmDialogOpen(false);
        setActivityToRemove(null);
    };

    const checkObs = () => {
        if (report.ongoingActivities !== undefined && report.ongoingActivities !== null) {
            report.ongoingActivities = toJS(report.ongoingActivities);
        } else {
            report.ongoingActivities = arr;
        }
    }
    checkObs();
    let nextId = uuid();

    const initialValues: OngoingActivity = {
        id: nextId,
        block: "",
        perSchedule: false,
        perScheduleComments: "",
        descriptionOfWorks: "",
        remarks: "",
        photoName: "",
        photo: null,
        siteInspectionReportId: report.id
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const readOnlyColumnDefs = [
        { headerName: "ID", field: "id", hide: true },
        {
            headerName: "Per Schedule", field: "perSchedule", resizable: true,
            cellRenderer: (params) => params.value ? "Yes" : "No"
        },
        { headerName: "Comments", field: "perScheduleComments", resizable: true },
        { headerName: "Description of Works", field: "descriptionOfWorks", resizable: true },
        { headerName: "Remarks", field: "remarks", resizable: true },
        { headerName: "Photo", field: "photo", hide: true },
    ]

    const editableColumnDefs = [
        { headerName: "ID", field: "id", hide: true },
        {
            headerName: "Per Schedule", field: "perSchedule", resizable: true,
            cellRenderer: (params) => params.value ? "Yes" : "No"
        },
        { headerName: "Comments", field: "perScheduleComments", resizable: true },
        { headerName: "Description of Works", field: "descriptionOfWorks", resizable: true },
        { headerName: "Remarks", field: "remarks", resizable: true },
        { headerName: "Photo", field: "photo", hide: true },
        {
            headerName: "Actions", field: "id", resizable: true,
            cellRenderer: (params) =>
                <div>
                    <Button color="primary" onClick={() => onEdit(params.rowIndex)} ><ModeEditOutlineOutlinedIcon /></Button>
                    <Button color="secondary" onClick={() => onRemove(params.rowIndex)} ><DeleteOutlinedIcon /></Button>
                </div>
        }
    ]

    const gridRef = useRef<AgGridReact>(null);

    const defaultColDef = {
        sortable: true,
        flex: 1, filter: true,
        scrollbars: true
    }

    const onEdit = (index: number) => {
        setEditingActivityIndex(index);
        handleClickOpen();
    }

    const handleCreateActivity = () => {
        formikProps.setFieldValue('newActivity', initialValues);
        setEditingActivityIndex(undefined);
        handleClickOpen();
    }

    return (
        <div>
            {readOnly ? null : (
                <Grid container>
                    <Grid>
                        <Button
                            variant="contained"
                            onClick={() => { handleCreateActivity(); }}
                            classes={{
                                root: classes.root,
                                label: classes.label
                            }}
                        >
                            Add New Ongoing Activity
                        </Button>
                    </Grid>
                </Grid>
            )}
            <div className="ag-theme-alpine" style={{ height: '300px' }}>
                <AgGridReact
                    ref={gridRef}
                    rowData={formikProps.values.ongoingActivities}
                    columnDefs={readOnly ? readOnlyColumnDefs : editableColumnDefs}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    pagination={true}
                    rowSelection='multiple'
                    paginationPageSize={4}
                />
            </div>
            <OngoingActivitiesDialogBox
                formikProps={formikProps}
                activityIndex={editingActivityIndex}
                open={open}
                handleClose={handleClose }
            />
            <ConfirmationDialog
                open={isConfirmDialogOpen}
                onClose={() => setIsConfirmDialogOpen(false)}
                onConfirm={handleConfirmDelete}
            />
        </div>
    );
}

export default OngoingActivities;
