import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { Pagination, PagingParams } from "../models/pagination";
import { Disbursement, DisbursementFormValues } from "../models/disbursement";
import agent from '../api/agent';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

export default class DisbursementStore {
    title = "Disbursements";
    selectedDisbursement: Disbursement | undefined = undefined;
    disbursementRegistry = new Map<string, Disbursement>();
    editMode = false;
    loading = false;
    loadingInitial = false;
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();
    predicate = new Map().set('all', true);

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.predicate.keys(),
            () => {
                this.disbursementRegistry.clear();
                this.pagingParams = new PagingParams();
                this.loadDisbursements();
            }
        )
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    setPredicate = (predicate: string, value: string | Date | number | boolean) => {
        const resetPredicate = () => {
            this.predicate.forEach((value, key) => {
                this.predicate.delete(key);
            })
        }
        switch (predicate) {
            case 'all':
                resetPredicate();
                this.predicate.set('all', true);
                break;
            case 'oblastId':
                this.predicate.delete('oblastId');
                this.predicate.set('oblastId', value);
                break;
            case 'spNameId':
                this.predicate.delete('spNameId');
                this.predicate.set('spNameId', value);
                break;
            case 'monthStart':
                this.predicate.delete('monthStart');
                this.predicate.set('monthStart', value);
                break;
            case 'monthEnd':
                this.predicate.delete('monthEnd');
                this.predicate.set('monthEnd', value);
                break;
        }
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());
        this.predicate.forEach((value, key) => {
            if (key === 'startDate') {
                params.append(key, (value as Date).toISOString())
            } else {
                params.append(key, value);
            }
        })
        return params;
    }

    get disbursementsByDate() {
        return Array.from(this.disbursementRegistry.values()).sort((a, b) =>
            new Date(a.month!).getTime() - new Date(b.month!).getTime()).reverse();
    }

    get groupedDisbursements() {
        return Object.entries(
            this.disbursementsByDate.reduce((disbursements, disbursement) => {
                const date = format(new Date(disbursement.month!), 'MMM yyyy');
                disbursements[date] = disbursements[date] ? [...disbursements[date], disbursement] : [disbursement];
                return disbursements;
            }, {} as { [key: string]: Disbursement[] })
        )
    }


    createDisbursement = async (disbursement: DisbursementFormValues) => {
        try {
            await agent.Disbursements.create(disbursement);
            const newDisbursement = new Disbursement(disbursement);
            this.setDisbursement(newDisbursement);
            runInAction(() => {
                this.selectedDisbursement = newDisbursement;
            })
            toast.success('Disbursement created');
        }
        catch (error) {
            console.log(error);
            toast.error('An error occurred creating disbursement')
        }
    }

    updateDisbursement = async (disbursement: DisbursementFormValues) => {
        await agent.Disbursements.update(disbursement);
        runInAction(() => {
            if (disbursement.id) {
                let updatedDisbursement = { ...this.getDisbursement(disbursement.id), ...disbursement };
                this.disbursementRegistry.set(disbursement.id, updatedDisbursement as Disbursement);
                this.selectedDisbursement = updatedDisbursement as Disbursement;
            }
        })
    }

    loadDisbursements = async () => {
        this.loadingInitial = true;
        try {
            const result = await agent.Disbursements.list(this.axiosParams);
            result.data.forEach(disbursement => {
                this.setDisbursement(disbursement);
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
        }
        catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
        }
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    loadDisbursement = async (id: string) => {
        let disbursement = this.getDisbursement(id);
        if (disbursement) {
            this.selectedDisbursement = disbursement;
            return disbursement;
        } else {
            this.loadingInitial = true;
            try {
                disbursement = await agent.Disbursements.details(id);
                this.setDisbursement(disbursement);
                runInAction(() => {
                    this.selectedDisbursement = disbursement;
                })
                this.setLoadingInitial(false);
                return disbursement;
            } catch (error) {
                console.log(error);
                toast.error('An error occurred loading disbursement!')
                this.setLoadingInitial(false);
            }
        }
    }


    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    private setDisbursement = (disbursement: Disbursement) => {
        this.disbursementRegistry.set(disbursement.id, disbursement);
    }

    private getDisbursement = (id: string) => {
        return this.disbursementRegistry.get(id);
    }

    clearSelectedDisbursement = () => {
        this.selectedDisbursement = undefined;
    }
}