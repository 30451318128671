import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { toast } from 'react-toastify';
import agent from "../api/agent";
import { SpNameFormValues } from '../models/spname';

export default class RefDataStore {

    loading: boolean = false;
    spNames: SpNameFormValues[] = [];

    constructor() {
        makeAutoObservable(this);
    }


    loadOblasts = async () => {
        try {
            let oblasts = await agent.Oblasts.list();
            return oblasts;
        }
        catch (error) {
            console.log(error)
            return null;
        }
    }

    loadSpNames = async (oblastId: number) => {
        try {
            let spNames = await agent.SpNames.list(oblastId);
            return spNames;
        }
        catch (error) {
            console.log(error);
            return null;
        }
    }

    loadAllSpNames = async () => {
        this.loading = true;
        try {
            let spNamesAll = await agent.SpNames.listallsubprojects();
            runInAction(() => {  // <-- Ensure state updates are batched together
                this.spNames = spNamesAll; // <-- Update the observable array
            });
            return spNamesAll;
        }
        catch (error) {
            console.log(error)
            return null;
        }
        finally {
            this.loading = false;
        }
    }

    loadIssueTypes = async () => {
        try {
            let issueTypes = await agent.IssueTypes.list();
            return issueTypes;
        }
        catch (error) {
            console.log(error);
            return null;
        }
    }

    loadSpNameStatuses = async () => {
        try {
            let statuses = await agent.SpNames.listspnamestatuses();
            return statuses;
        }
        catch (error) {
            console.log(error);
            return null;
        }
    }


    loadSpNameDetails = async (id: number) => {
        try {
            if (id <= 0) {
                return null;
            }
            let spName = await agent.SpNames.getbyid(id);
            return spName;
        }
        catch (error) {
            console.log(error)
            return null;
        }
    }

    loadHealthAndSafetyObservations = async () => {
        try {
            let observations = await agent.HealthAndSafetyObservations.list();
            return observations;
        }
        catch (errors) {
            console.log(errors);
        }
    }

    updateSpName = async (spName: SpNameFormValues) => {
        try {
            console.log('spName 2', spName);
            await agent.SpNames.update(spName);
            runInAction(() => {
                // Update the spName in the observable array
                this.spNames = this.spNames.map(sp => (sp.id === spName.id ? spName : sp));
            });
            toast.success('Sub Project updated');
        }
        catch (error) {
            console.log(error);
            toast.error('An error occurred updating sub project');
        }
    }

    createSpName = async (spName: SpNameFormValues) => {
        try {
            await agent.SpNames.create(spName);
            toast.success('Sub Project created');
        }
        catch (error) {
            console.log(error);
            toast.error('An error occurred creating sub project')
        }
    }
}