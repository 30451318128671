import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material/';
import { Grid, FormControl, RadioGroup, FormLabel, Radio, FormControlLabel, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { IssueType } from '../../app/models/issuetype';
import { useStore } from '../../app/stores/store';
import { FormikProps } from 'formik';
import { SiteInspectionReportFormValues } from '../../app/models/siteinspectionreport';
import SirImageUpload from '../../app/common/SirImageUpload';
import SirTextArea from '../../app/common/SirTextArea';
import SirTextInput from '../../app/common/SirTextInput';
import { QualityAndProgress } from '../../app/models/qualityandprogress';

interface Res {
    text: string;
    value: number;
}

interface QualityAndProgressDialogBoxProps {
    formikProps: FormikProps<SiteInspectionReportFormValues>;
    qualityAndProgressIndex?: number; // Index of the qualityAndProgress being edited, if applicable
    handleClose: () => void;
    open: boolean;
}

const QualityAndProgressDialogBox: React.FC<QualityAndProgressDialogBoxProps> = ({ formikProps, qualityAndProgressIndex, handleClose, open }) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            "& .MuiFormControl-root": {
                width: "90%",
                margin: theme.spacing(1),
            },
        },
    }));
    const classes = useStyles();

    let results: Res[] = [];


    const [getQp, setQp] = React.useState<IssueType[]>([]);
    const isCreatingNew = qualityAndProgressIndex === undefined;
    const fieldNamePrefix = isCreatingNew ? 'newQualityAndProgress' : `qualityAndProgresses[${qualityAndProgressIndex}]`;

    const { refDataStore } = useStore();

    React.useEffect(() => {
        refDataStore.loadIssueTypes().then((res) => setQp(res ? res : []))
            .catch((error) => console.log(error));
    }, []);

    const mapQpToResult = () => {
        getQp.forEach(issueType => {
            let r = {} as Res;
            r.text = issueType.name;
            r.value = issueType.id;
            results.push(r);
        })
    }

    mapQpToResult();

    const handleSave = () => {
        if (isCreatingNew) {
            const values: any = formikProps.values;
            // Logic to add a new activity to the qualityAndProgresses array
            formikProps.setFieldValue('qualityAndProgresses', [...formikProps.values.qualityAndProgresses!, values.newQualityAndProgress]);
        } else {
            let updatedQualityAndProgresses = [...formikProps.values.qualityAndProgresses!];
            updatedQualityAndProgresses[qualityAndProgressIndex] = formikProps.values.qualityAndProgresses![qualityAndProgressIndex];
            formikProps.setFieldValue('qualityAndProgresses', updatedQualityAndProgresses);
        }
        handleClose(); // Close the dialog box
    };

    const extendedValues = formikProps.values as SiteInspectionReportFormValues & { newQualityAndProgress?: QualityAndProgress };

    const issueTypeRadioValue = isCreatingNew
        ? extendedValues.newQualityAndProgress?.issueTypeId?.toString() || ''
        : extendedValues.qualityAndProgresses![qualityAndProgressIndex]?.issueTypeId?.toString() || '';

    const impactRadioValue = isCreatingNew
        ? extendedValues.newQualityAndProgress?.impact?.toString() || ''
        : extendedValues.qualityAndProgresses![qualityAndProgressIndex]?.impact?.toString() || 'false';

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {isCreatingNew ? "Create New Quality & Progress" : "Update Quality & Progress"}
                </DialogTitle>
                <DialogContent>
                    <form className={classes.root}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel>Issue Type</FormLabel>
                                </FormControl>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        name={`${fieldNamePrefix}.issueTypeId`}
                                        value={issueTypeRadioValue}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            let valueText = "";
                                            if (value !== undefined && value === "1")
                                                valueText = "Quality"
                                            else if (value !== undefined && value === "2")
                                                valueText = "Progress";
                                            formikProps.setFieldValue(`${fieldNamePrefix}.issueTypeId`, value);
                                            formikProps.setFieldValue(`${fieldNamePrefix}.issueTypeText`, valueText)
                                        }}
                                    >
                                        <FormControlLabel value="1" control={<Radio />} label="Quality" />
                                        <FormControlLabel value="2" control={<Radio />} label="Progress" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <SirTextArea
                                    name={`${fieldNamePrefix}.comments`}
                                    placeholder="Comments"
                                    minRows={2}
                                    maxRows={4}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SirTextArea
                                    name={`${fieldNamePrefix}.remarks`}
                                    placeholder="Remarks"
                                    minRows={2}
                                    maxRows={4}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel>Impact</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-label="Impact"
                                        name={`${fieldNamePrefix}.impact`}
                                        value={impactRadioValue}
                                        onChange={(e) => {
                                            const value = e.target.value === 'true';
                                            formikProps.setFieldValue(`${fieldNamePrefix}.impact`, value);
                                        }}
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="false" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <SirTextInput
                                    name={`${fieldNamePrefix}.photoName`}
                                    placeholder="Photo Name"
                                    label="Name of Photo"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SirImageUpload name={`${fieldNamePrefix}.photo`} />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" variant="outlined">Cancel</Button>
                    <Button onClick={() => handleSave()} color="primary" variant="contained">
                        {isCreatingNew ? 'Create' : 'Update'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


export default QualityAndProgressDialogBox;