import React, { useState, useEffect, useRef } from "react";
import { Query } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import { Chart, ReactGoogleChartEvent } from "react-google-charts";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../app/stores/store";
import { SiteInspectionReport } from "../../../../app/models/siteinspectionreport";
import CardFormViewPopup from "../../../CardFormViewPopup";
import LoadingComponent from "../../../layout/LoadingComponent";

interface SirReportsDashboardProps {
    oblast: string;
    selectedSpNameSpCode: string;
    onSelect: (selectedSirId: string) => void;
}

const SirReportsDashboard: React.FC<SirReportsDashboardProps> = ({ oblast, selectedSpNameSpCode, onSelect }) => {
    const { siteInspectionReportStore } = useStore();
    const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
    const [selectedReport, setSelectedReport] = useState<SiteInspectionReport | null | undefined>(null);
    const [showPopup, setShowPopup] = useState(false);
    const chartWrapperRef = useRef<any>(null);

    const { loadSiteInspectionReport } = siteInspectionReportStore;
    const query: Query = {
        dimensions: [
            'VwSiteInspectionReport.sirspdetails',
            'VwSiteInspectionReport.id',
            'VwSiteInspectionReport.inspectiondate',
        ],
        order: {
            'VwSiteInspectionReport.inspectiondate': 'desc',
        },
        timeDimensions: [{
            dimension: 'VwSiteInspectionReport.inspectiondate',
            dateRange: ['2006-01-01', '2036-12-31']
        }],
        filters: [
            {
                dimension: 'VwSiteInspectionReport.oblastname',
                operator: 'contains',
                values: [oblast]
            },
            {
                dimension: 'VwSiteInspectionReport.spnamespcode',
                operator: 'contains',
                values: [selectedSpNameSpCode]
            }
        ]
    };

    const options = {
        showRowNumber: true,
        page: "enable",
        pageSize: 20
    };

    const labels = ["Report"];

    const { resultSet, isLoading, error } = useCubeQuery(query);

    useEffect(() => {
        if (selectedRowIndex !== null && chartWrapperRef.current) {
            const chartWrapper = chartWrapperRef.current;
            const chart = chartWrapper.getChart();
            chart.setSelection([{ row: selectedRowIndex }]);
        }
    }, [selectedRowIndex, showPopup]); // Ensure selection is set when popup visibility changes

    if (isLoading || !resultSet) {
        return <LoadingComponent content='Loading...' />;
    }

    if (error) {
        return <div>{error.toString()}</div>;
    }

    const rows = resultSet.tablePivot().map(row => ({
        sirspdetails: row['VwSiteInspectionReport.sirspdetails'],
        id: row['VwSiteInspectionReport.id'],
    }));

    const data = [
        labels,
        ...rows.map(row => [row.sirspdetails]),
    ];

    const chartEvents: ReactGoogleChartEvent[] = [{
        eventName: 'select',
        callback: ({ chartWrapper }) => {
            const chart = chartWrapper.getChart();
            const selection = chart.getSelection()[0];
            if (selection) {
                const reportId = rows[selection.row].id;
                setSelectedRowIndex(selection.row);
                loadSiteInspectionReport(reportId.toString()).then(sReport => {
                    setSelectedReport(new SiteInspectionReport(sReport));
                    onSelect(reportId.toString());
                    setShowPopup(true);
                });
            }
        }
    }];

    return (
        <>
            <style>
                {`
                    .google-visualization-table-tr-sel {
                        background-color: #cfe8fc !important; /* Highlight color */
                    }
                `}
            </style>
            <div style={{ width: '500px', height: '500px' }}>
                <Chart
                    chartType="Table"
                    width="100%"
                    height="500px"
                    data={data}
                    options={options}
                    chartEvents={chartEvents}
                    getChartWrapper={chartWrapper => {
                        chartWrapperRef.current = chartWrapper;
                    }}
                />
                {selectedReport && showPopup &&
                    <CardFormViewPopup selectedReport={selectedReport} onClose={() => setShowPopup(false)} />
                }
            </div>
        </>
    );
};

export default observer(SirReportsDashboard);
