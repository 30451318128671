import React from 'react';
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Button } from "semantic-ui-react";
import SirTextInput from "../../app/common/SirTextInput";
import { useStore } from "../../app/stores/store";
import * as Yup from 'yup';
interface Props {
    setEditMode: (editMode: boolean) => void;
}
export default observer(function ProfileUpdatePassword({ setEditMode }: Props) {
    const { userStore: { user, changePassword } } = useStore();
    return (
        <Formik
            initialValues={{
                username: user!.username,
                currentpassword: '',
                newpassword: '',
                confirmpassword: ''
            }}
            onSubmit={values => {
                changePassword(values).then(() => {
                    setEditMode(false);
                })
            }}
            validationSchema={Yup.object({
                currentpassword: Yup.string().required('Please insert existing password'),
                newpassword: Yup.string().required('Password must not be empty'),
                confirmpassword: Yup.string().oneOf([Yup.ref('newpassword'), null], "Passwords don't match!").required('Password must not be empty')
            })}
        >
            {({ isSubmitting, isValid, dirty }) => (
                <Form className='ui form'>
                    <SirTextInput placeholder='Current Password'
                        name='currentpassword'
                        type='password' />
                    <SirTextInput placeholder='New Password'
                        name='newpassword'
                        type='password' />
                    <SirTextInput placeholder='Confirm Password'
                        name='confirmpassword'
                        type='password' />
                    <Button
                        positive
                        type='submit'
                        loading={isSubmitting}
                        content='Change Password'
                        floated='right'
                        disabled={!isValid || !dirty}
                    />
                </Form>
            )}
        </Formik>
    )
})