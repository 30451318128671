import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { SpName } from '../../app/models/spname';
import { useStore } from '../../app/stores/store';
import { Typography, Container, IconButton, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import type { } from '@mui/x-data-grid/themeAugmentation';
import { teal } from '@material-ui/core/colors';
import { DataGrid } from '@mui/x-data-grid';
import EditSpName from './EditSpName';

export default observer(function SubProjects() {
    const { refDataStore } = useStore();
    const { loadAllSpNames, spNames } = refDataStore;
    const [getSpNames, setSpNames] = useState<SpName[]>([]);

    const [selectedSpName, setSelectedSpName] = useState<SpName | null>(null);
    const [editModalOpen, setEditModalOpen] = useState(false);

    useEffect(() => {
        if (spNames.length === 0) {
            loadAllSpNames()
                .then((res) => {
                    if (Array.isArray(res)) {
                        setSpNames(res);
                    } else {
                        setSpNames([]);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
        }
    }, [loadAllSpNames, spNames]);

    const customTheme = createTheme({
        components: {
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        '& .MuiDataGrid-columnsContainer': {
                            backgroundColor: teal[800],
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold',
                            color: 'darkblue',
                        },
                    },
                },
            },
        },
    });

    const columns = [
        {
            field: 'edit',
            headerName: 'Edit',
            width: 50,
            renderCell: (params) => (
                <IconButton
                    color="primary"
                    onClick={() => handleEditClick(params.row)}
                >
                    <EditIcon />
                </IconButton>
            ),
        },
        { field: 'name', headerName: 'Sub Project Name', width: 150 },
        { field: 'spCode', headerName: 'Sub Project Code', width: 150 },
        { field: 'oblastName', headerName: 'Oblast Name', width: 210 },
        { field: 'statusName', headerName: 'Status', width: 150 },
        { field: 'contractor', headerName: 'Contractor', width: 150 },
        { field: 'usifRepresentative', headerName: 'USIF rep.', width: 150 },
        { field: 'contractorRepresentative', headerName: 'Contractor rep.', width: 150 },
    ];

    const handleEditClick = (spName: SpName) => {
        setSelectedSpName(spName);
        setEditModalOpen(true);
    };

    const handleCreateClick = () => {
        setSelectedSpName(null);
        setEditModalOpen(true);
    };

    const handleCloseModal = () => {
        setEditModalOpen(false);
        setSelectedSpName(null);
        reloadSpNames();
    };

    // Reloading the sub projects from API:
    const reloadSpNames = () => {
        loadAllSpNames()
            .then((res) => {
                if (Array.isArray(res)) {
                    setSpNames(res);
                } else {
                    setSpNames([]);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom component="div" color='teal' align="center">
                All Sub Projects
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={handleCreateClick}
            >
                Create New Sub Project
            </Button>
            <div style={{ height: 400, width: '100%' }}>
                <ThemeProvider theme={customTheme}>
                    <DataGrid
                        rows={spNames}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        checkboxSelection={false}
                    />
                </ThemeProvider>
            </div>
            <EditSpName
                spName={selectedSpName}
                isOpen={editModalOpen}
                onClose={handleCloseModal}
            />
        </Container>
    );
});
