import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Profile } from "../models/profile";
import { store } from "./store";
import { toast } from "react-toastify";

export default class ProfileStore {
    profile: Profile | null = null;
    loadingProfile = false;
    uploading = false;
    loading = false;
    loadingFollowings = false;
    activeTab = 0;
    loadingActivities = false;

    constructor() {
        makeAutoObservable(this);
    }

    setActiveTab = (activeTab: any) => {
        this.activeTab = activeTab;
    }

    get isCurrentUser() {
        if (store.userStore.user && this.profile) {
            return store.userStore.user.username === this.profile.username;
        }
        return false;
    }

    loadProfile = async (username: string) => {
        this.loadingProfile = true;
        try {
            const profile = await agent.Profiles.get(username);
            runInAction(() => {
                this.profile = profile;
                this.loadingProfile = false;
            })
        } catch (error) {
            toast.error('Problem loading profile');
            runInAction(() => this.loadingProfile = false);
        }
    }

    updateProfile = async (profile: Partial<Profile>) => {
        this.loading = true;
        try {
            await agent.Profiles.updateProfile(profile);
            runInAction(() => {
                if (store.userStore.user) {
                    if (profile.displayName && profile.displayName !== store.userStore.user!.displayName) {
                        store.userStore.setDisplayName(profile.displayName);
                    }
                }
                this.profile = { ...this.profile, ...profile as Profile };
                toast.success("Profile updated");
                this.loading = false;
            })
        } catch (error) {
            toast.error('Problem updating profile');
            runInAction(() => this.loading = false);
        }
    }
}
