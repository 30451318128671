import React, { useState, useEffect } from "react";
import { Query } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import { Loader } from 'semantic-ui-react';
import { observer } from "mobx-react-lite";
//import LeafletMap from "../../../../app/common/LeafletMap";
import { MapLocation } from "../../../../app/models/maplocation";
import { MapContainer, TileLayer, CircleMarker, Popup } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import "leaflet-fullscreen/dist/Leaflet.fullscreen.js";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";

export default observer(function MapDashboard({ oblast, selectedSpNameSpCode }: { oblast: string, selectedSpNameSpCode: string }) {

    const query: Query = {
        dimensions: [
            'VwSiteInspectionReport.oblastname',
            'VwSiteInspectionReport.sirspdetails',
            'VwSiteInspectionReport.coordinatex',
            'VwSiteInspectionReport.coordinatey'
        ],
        order: {
            'VwSiteInspectionReport.inspectiondate': 'desc',
        },

        measures: ['VwSiteInspectionReport.count'],
        timeDimensions: [{
            dimension: 'VwSiteInspectionReport.createdon',
            dateRange: ['2006-01-01', '2033-12-31']
        }],
        filters: [
            {
                dimension: 'VwSiteInspectionReport.oblastname',
                operator: 'contains',
                values: [oblast]
            },
            {
                dimension: 'VwSiteInspectionReport.spnamespcode',
                operator: 'contains',
                values: [selectedSpNameSpCode]
            }
        ]
    };


    const { resultSet, isLoading, error, progress } = useCubeQuery(query);

    if (isLoading || !resultSet) {
        return <Loader progress={progress} />;
    }

    if (error) {
        return <div>{error.toString()}</div>;
    }
    const mapLocations: MapLocation[] = [];

    const rows = resultSet
        .tablePivot()
        .map((row) => ({
            oblastname: row["VwSiteInspectionReport.oblastname"],
            sirspdetails: row["VwSiteInspectionReport.sirspdetails"],
            x: row["VwSiteInspectionReport.coordinatex"],
            y: row["VwSiteInspectionReport.coordinatey"]
        }));

    const mapResultToMapLocation = () => {
        rows.forEach(row => {
            let m = {} as MapLocation;
            if (row['x'] && row['y'] && Number(row['x']) !== 0 && Number(row['y'] !== 0)) {
                m.oblastname = row['oblastname'] as string;
                m.sirspdetails = row['sirspdetails'] as string;
                m.x = row['x'] as number;
                m.y = row['y'] as number;
                mapLocations.push(m);
            }
        })
    };
    mapResultToMapLocation();

    const bounds = mapLocations.reduce((acc, curr) => {
        return [
            [Math.min(acc[0][0], curr['x']), Math.min(acc[0][1], curr['y'])],
            [Math.max(acc[1][0], curr['x']), Math.max(acc[1][1], curr['y'])],
        ];
    }, [[Infinity, Infinity], [-Infinity, -Infinity]]);
    const center: [number, number] = [(bounds[0][0] + bounds[1][0]) / 2, (bounds[0][1] + bounds[1][1]) / 2];
    const zoom = 6;
    const styles = {
        mapContainer: {
            height: '500px',
            width: '100%',
            zIndex: 0,
        },
    };
    const position: [number, number] = [48.3794, 36.2304];

    return (
        <MapContainer
            center={position}
            zoom={zoom}
            boxZoom
            style={styles.mapContainer}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {Object.values(mapLocations).map((location) => (
                <CircleMarker
                    key={`${location.sirspdetails}`}
                    center={[location.y, location.x]}
                    radius={Math.sqrt(1) * 10}
                >
                    <Popup>
                        <div>
                            <h2>{location.oblastname}</h2>
                            <p>{location.sirspdetails}</p>
                        </div>
                    </Popup>
                </CircleMarker>
            ))}
        </MapContainer>
    );
});
