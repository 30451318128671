import { ErrorMessage, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Header, Label, Button, Divider } from 'semantic-ui-react';  // Import Divider from semantic-ui-react
import SirTextInput from '../../app/common/SirTextInput';
import { useStore } from '../../app/stores/store';
import ForgotPasswordForm from './ForgotPasswordForm';  // Import the ForgotPasswordForm

export default observer(function LoginForm() {
    const { userStore, modalStore } = useStore();
    return (
        <Formik
            initialValues={{ username: '', password: '', error: null }}
            onSubmit={(values, { setErrors }) => userStore.login(values).catch(error =>
                setErrors({ error: 'Invalid username or password' }))}
        >
            {({ handleSubmit, isSubmitting, errors }) => (
                <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                    <Header as='h2' content='Login to OMS' color='teal' textAlign='center' />
                    <SirTextInput name='username' placeholder='Username' />
                    <SirTextInput name='password' placeholder='Password' type='password' />
                    <ErrorMessage
                        name='error' render={() =>
                            <Label style={{ marginBottom: 10 }} basic color='red' content={errors.error} />}
                    />
                    <Button loading={isSubmitting} positive content='Login' type='submit' fluid />
                    <Divider />  {/* Divider to create some space */}
                    <div style={{ textAlign: 'right', marginTop: '10px' }}>  {/* Align to the right */}
                        <a
                            href="#"  // Prevents default navigation
                            onClick={(e) => {
                                e.preventDefault();  // Prevents default navigation
                                modalStore.openModal(<ForgotPasswordForm />);
                            }}
                            style={{ color: 'blue', cursor: 'pointer' }}  // Styled as a link
                        >
                            Forgot Password?
                        </a>
                    </div>
                </Form>
            )}
        </Formik>
    )
})
