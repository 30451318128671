import React, { useState, useEffect } from "react";
import { Query } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import { Chart } from "react-google-charts";
import { observer } from "mobx-react-lite";
import LoadingComponent from "../../../layout/LoadingComponent";

export default observer(function FundsDisbursedDashboard({
    oblast,
    selectedSpNameSpCode,
    inspectionStartDate,
    inspectionEndDate
}: {
    oblast: string;
    selectedSpNameSpCode: string;
    inspectionStartDate: Date;
    inspectionEndDate: Date;
}) {
    const monthToNumber: Record<string, number> = {
        Jan: 1,
        Feb: 2,
        Mar: 3,
        Apr: 4,
        May: 5,
        Jun: 6,
        Jul: 7,
        Aug: 8,
        Sep: 9,
        Oct: 10,
        Nov: 11,
        Dec: 12,
    } as const;
    const query: Query = {
        dimensions: [
            "VwDisbursements.monthofdisbursement",
            "VwDisbursements.yearofdisbursement",
        ],
        order: {
            "VwDisbursements.yearofdisbursement": "desc",
        },
        measures: ["VwDisbursements.amountofpayment"],
        timeDimensions: [
            {
                dimension: "VwDisbursements.disbursementdate",
                dateRange: [inspectionStartDate.toDateString(), inspectionEndDate.toDateString()],
            },
        ],
        filters: [
            {
                dimension: "VwDisbursements.oblastname",
                operator: "contains",
                values: [oblast],
            },
            {
                dimension: "VwDisbursements.spnamespcode",
                operator: "contains",
                values: [selectedSpNameSpCode],
            },
        ],
    };

    // Let's specify a few options like pagination for the table
    const options = {
        title: "Disbursements per month and year",
        colorAxis: {
            maxValue: 500,
        },
        legend: "none",
        vAxis: {
            title: "Month and Year",
        },
        hAxis: {
            title: "UAH",
            minValue: 0,
        },
        colors: ["#ffaa01"],
    };

    const { resultSet, isLoading, error } = useCubeQuery(query);

    const [totalAmountOfPayment, setTotalAmountOfPayment] = useState<number>(
        0
    );

    useEffect(() => {
        if (resultSet) {
            const sum = resultSet
                .tablePivot()
                .reduce(
                    (total: number, row: any) =>
                        total + Number(row["VwDisbursements.amountofpayment"]),
                    0
                );
            setTotalAmountOfPayment(sum);
        }
    }, [resultSet]);

    if (isLoading || !resultSet) {
        return <LoadingComponent content="Loading..." />;
    }

    if (error) {
        return <div>{error.toString()}</div>;
    }

    const rows = resultSet
        .tablePivot()
        .map((row) => ({
            key: `${row["VwDisbursements.yearofdisbursement"]}-${String(
                row["VwDisbursements.monthofdisbursement"]
            )}`,
            value: Number(row["VwDisbursements.amountofpayment"]),
        }))
        .reduce((acc, curr) => {
            const { key, value } = curr;
            acc[key] = (acc[key] || 0) + value;
            return acc;
        }, {});

    const orderedRows = Object.entries(rows)
        .sort(([keyA], [keyB]) => {
            const [yearA, monthA] = keyA.split("-");
            const [yearB, monthB] = keyB.split("-");
            // Order by year first, then by month
            if (yearA !== yearB) {
                return Number(yearB) - Number(yearA);
            } else {
                return monthToNumber[monthB] - monthToNumber[monthA];
            }
        })
        .map(([key, value]) => ({
            month: key.split("-")[1],
            year: key.split("-")[0],
            value,
        }));

    const data = orderedRows.map((row) => [
        `${row.year} - ${row.month}`,
        row.value,
    ]);

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', fontSize: '19px', marginTop: '1px' }}>
                Total Disbursement Value
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', fontSize: '24px', marginTop: '10px' }}>
                <strong>{totalAmountOfPayment.toLocaleString()} UAH</strong>
            </div>
            <Chart
                chartType="BarChart"
                width="100%"
                height={500}
                data={[['Month and Year', 'Value disbursed'], ...data]}
                options={options}
            />
        </>);
});
