import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Icon, Modal } from 'semantic-ui-react';
import { SiteInspectionReport } from '../app/models/siteinspectionreport';
import { store } from '../app/stores/store';
import CardFormView from './CardFormView';
import { Tooltip } from 'react-tooltip';

interface Props {
    selectedReport: SiteInspectionReport | null | undefined;
    onClose: () => void;
}

const CardFormViewPopup = ({ selectedReport, onClose }: Props) => {
    if (!selectedReport) {
        return null;
    }
    const { spNameString, oblastString, id, inspectionDate, isFinal } = selectedReport
    const onButtonClick = () => {
        store.siteInspectionReportStore.downloadSiteInspectionReport(selectedReport.id!).then(response => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            let dateString = selectedReport.inspectionDate ? format(new Date(selectedReport.inspectionDate), "ddMMyyyy") : "";
            link.setAttribute('download', "SIR-USIF_" + spNameString + "_" + dateString + ".pdf");
            document.body.appendChild(link);
            link.click();
        })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <Modal open onClose={onClose}>
            <Modal.Header>Site Inspection Report Details</Modal.Header>
            <Modal.Content>
                {selectedReport ? (
                    <Grid>
                        <Grid.Row>
                            {isFinal ? (
                                <>
                                    <Button
                                        as={Link}
                                        to={`/reports/${id}`}
                                        icon
                                        color='yellow'
                                        size='mini'
                                        title='View'
                                        data-tip='View'
                                    >
                                        <Icon name='eye' />
                                    </Button>
                                    <Tooltip />
                                </>) :
                                (
                                    <>
                                        <Button
                                            as={Link}
                                            to={`/manage/${id}`}
                                            icon
                                            color='teal'
                                            size='mini'
                                            title='Edit'
                                            data-tip='Edit'
                                        >
                                            <Icon name='edit' />
                                        </Button>
                                        <Tooltip />
                                    </>)}
                            <Button
                                onClick={onButtonClick}
                                icon
                                color='green'
                                size='mini'
                                title='Download Report'
                                data-tip='Download Report'
                            >
                                <Icon name='download' />
                            </Button>
                            <Tooltip />
                        </Grid.Row>
                    </Grid>
                ) : (<></>)}
                <CardFormView formValues={selectedReport} />

            </Modal.Content>
        </Modal>
    );
};
export default observer(CardFormViewPopup);