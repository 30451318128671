import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { DataGrid } from '@mui/x-data-grid';
import { User } from '../../app/models/user';
import { useStore } from '../../app/stores/store';
import EditUser from './EditUser';
import { Typography, Container, IconButton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import type { } from '@mui/x-data-grid/themeAugmentation';
import { teal } from '@material-ui/core/colors';
import LockIcon from '@mui/icons-material/Lock'; // Import the lock icon for the change password button
import UpdatePassword from './UpdatePassword';


export default observer(function ViewAllUsers() {
    const { userStore } = useStore();
    const { loadUsers } = userStore;
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [passwordModalOpen, setPasswordModalOpen] = useState(false);

    const handlePasswordClick = (user: User) => {
        setSelectedUser(user);
        setPasswordModalOpen(true);
    };

    useEffect(() => {
        loadUsers()
            .then((res) => {
                if (Array.isArray(res)) {
                    setUsers(res);
                } else {
                    setUsers([]);
                }
            })       
            .catch((error) => {
                console.error(error);
            });
    }, [loadUsers]);

    const customTheme = createTheme({
        components: {
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        '& .MuiDataGrid-columnsContainer': {
                            backgroundColor: teal[800],
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold',
                            color: 'darkblue',
                        },
                    },
                },
            },
        },
    });

    const columns = [
        { field: 'username', headerName: 'Username', width: 150 },
        { field: 'fullName', headerName: 'Full Name', width: 230 },
        { field: 'selectedRole', headerName: 'Role', width: 140 },
        { field: 'phoneNumber', headerName: 'Phone Number', width: 160 },
        {
            field: 'edit',
            headerName: '',
            width: 50,
            renderCell: (params) => (
                <IconButton
                    color="primary"
                    onClick={() => handleEditClick(params.row)}
                >
                    <EditIcon />
                </IconButton>
            ),
        },
        {
            field: 'updatePassword',
            headerName: '',
            width: 50,
            renderCell: (params) => (
                <IconButton
                    color="primary"
                    onClick={() => handlePasswordClick(params.row)}
                >
                    <LockIcon />
                </IconButton>
            ),
        },
    ];

    const handleEditClick = (user: User) => {
        setSelectedUser(user);
        setEditModalOpen(true);
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom component="div" color='teal' align="center">
                All Users
            </Typography>
            <div style={{ height: 400, width: '100%' }}>
                <ThemeProvider theme={customTheme}>
                    <DataGrid
                        rows={users}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        checkboxSelection={false}
                    />
                </ThemeProvider>
            </div>
            <EditUser
                user={selectedUser}
                isOpen={editModalOpen}
                onClose={() => {
                    setEditModalOpen(false);
                    setSelectedUser(null);
                }}
            />
            <UpdatePassword
                user={selectedUser}
                isOpen={passwordModalOpen}
                onClose={() => {
                    setPasswordModalOpen(false);
                    setSelectedUser(null);
                }}
            />
        </Container>
    );
});
