import React, { useState, useEffect } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { observer } from 'mobx-react-lite';
import { Button, Header, Modal } from 'semantic-ui-react';
import * as Yup from 'yup';
import ValidationError from '../errors/ValidationErrors';
import SirTextInput from '../../app/common/SirTextInput';
import SirSelectInput from '../../app/common/SirSelectInput';
import { Role } from '../../app/models/role';
import { User } from '../../app/models/user';
import { useStore } from '../../app/stores/store';

interface EditUserProps {
    user: User | null;
    isOpen: boolean;
    onClose: () => void;
}

interface Res {
    text: string;
    value: string;
}

export default observer(function EditUser({
    user,
    isOpen,
    onClose
}: EditUserProps) {
    const { userStore } = useStore();
    const [getRoles, setRoles] = useState<Role[]>([]);
    const [formValues, setFormValues] = useState({
        name: '',
        username: '',
        email: '',
        selectedrole: '',
        phoneNumber: '',
        error: null
    });

    useEffect(() => {
        if (user) {
            setFormValues({
                name: user.fullName || '',
                username: user.username || '',
                email: user.email || '',
                selectedrole: user.selectedRole || '',
                phoneNumber: user.phoneNumber || '',
                error: null,
                // ... other form fields
            });
        }
    }, [user]);

    useEffect(() => {
        userStore
            .loadRoles()
            .then((res) => setRoles(res || []))
            .catch((error) => console.log(error));
    }, [userStore]);

    const mapRolesToResult = () => {
        return getRoles.map((role) => ({
            text: role.name,
            value: role.name,
        }));
    };

    const handleSubmit = async (values: any) => {
        try {
            await userStore.updateUser({ ...user, ...values });
            onClose();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Modal open={isOpen} onClose={onClose} size="tiny">
            <Modal.Header>Edit User</Modal.Header>
            <Modal.Content>
                <Formik
                    enableReinitialize
                    initialValues={formValues}
                    onSubmit={handleSubmit}
                    validationSchema={Yup.object({
                        name: Yup.string().required('Full name required'),
                        username: Yup.string().required('Username required'),
                        email: Yup.string().required('Email required'),
                        selectedrole: Yup.string().required('User must belong to a role'),
                    })}
                >
                    {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
                        <Form
                            className="ui form error"
                            onSubmit={handleSubmit}
                            autoComplete="off"
                        >
                            <Header
                                as="h2"
                                content="Edit User"
                                color="teal"
                                textAlign="center"
                            />
                            <SirTextInput placeholder="Full Name" name="name" label="Full Name" />
                            <SirTextInput placeholder="Username" name="username" label="Username" />
                            <SirTextInput placeholder="Email" name="email" label="Email" />
                            <SirTextInput placeholder="Phone Number" name="phoneNumber" label="Phone Number" />
                            <SirSelectInput
                                options={mapRolesToResult()}
                                placeholder="Role"
                                name="selectedrole"
                                label="Role"
                            />
                            <ErrorMessage name="error" render={() => (
                                <ValidationError errors={errors.error} />
                            )} />
                            <Button
                                disabled={!isValid || !dirty || isSubmitting}
                                loading={isSubmitting}
                                positive
                                content="Save"
                                type="submit"
                                fluid
                            />
                        </Form>
                    )}
                </Formik>
            </Modal.Content>
        </Modal>
    );
});
