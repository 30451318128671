import { makeAutoObservable, runInAction } from "mobx";
import { User, UserFormValues, UpdatePasswordFormValues, EditUserFormValues, UpdateOtherPasswordFormValues, ForgotPasswordFormValues, ResetPasswordFormValues } from "../models/user";
import { store } from "./store";
import agent from "../api/agent";
import { router } from "../router/Routes";
import { toast } from "react-toastify";

export default class UserStore {
    user: User | null = null;
    refreshTokenTimeout: any;

    constructor() {
        makeAutoObservable(this)
    }

    get isLoggedIn() {
        return !!this.user;
    }

    get getUserRoles() {
        return this.user && this.user.roles ? this.user.roles : [""];
    }

    login = async (creds: UserFormValues) => {
        try {
            const user = await agent.Account.login(creds);
            store.commonStore.setToken(user.token);
            this.startRefreshTokenTimer(user);
            runInAction(() => this.user = user);
            router.navigate('/dashboard');
            store.modalStore.closeModal();
        } catch (error) {
            throw error;
        }
    }

    changePassword = async (passwordDetails: UpdatePasswordFormValues) => {
        try {
            const user = await agent.Account.current();
            await agent.Account.changePassword(passwordDetails);
            runInAction(() => this.user = user);
            toast.success('Password updated');
        } catch (error) {
            console.log(error);
            toast.error('An error occurred updating password');
        }
    }

    forgotPassword = async (details: ForgotPasswordFormValues) => {
        try {
            await agent.Account.forgotPassword(details);
            toast.success('Email sent');
        } catch (error) {
            console.log(error);
            toast.error('An error occurred sending email for password reset');
        }
    }

    resetPassword = async (details: ResetPasswordFormValues) => {
        try {
            await agent.Account.resetPassword(details);
            toast.success('Password reset successfully');
        } catch (error) {
            console.log(error);
            toast.error('An error occurred resetting password');
        }
    }

    register = async (creds: UserFormValues) => {
        try {
            const user = await agent.Account.register(creds);
            store.commonStore.setToken(user.token);
            runInAction(() => this.user = user);
            router.navigate('/reports');
            store.modalStore.closeModal();
        } catch (error) {
            throw error;
        }
    }

    logout = () => {
        store.commonStore.setToken(null);
        window.localStorage.removeItem('jwt');
        this.user = null;
        router.navigate('/');
    }

    getUser = async () => {
        try {
            const user = await agent.Account.current();
            store.commonStore.setToken(user.token);
            runInAction(() => this.user = user);
            this.startRefreshTokenTimer(user);
        } catch (error) {
            console.log(error);
        }
    }

    updateUser = async (formValues: EditUserFormValues) => {
        try {
            await agent.Users.updateuser(formValues);
            toast.success('User updated');
        } catch (error) {
            toast.error('An error occurred updating user');
           throw error;
        }
    }

    updateOtherUserPassword = async (formValues: UpdateOtherPasswordFormValues) => {
        try {
            const user = await agent.Account.current();
            await agent.Account.changeOtherPassword(formValues);
            runInAction(() => this.user = user);
            toast.success('Password updated');
        } catch (error) {
            console.log(error);
            toast.error('An error occurred updating password');
        }
    }

    loadRoles = async () => {
        try {
            let roles = await agent.Roles.list();
            return roles;
        }
        catch (error) {
            console.log(error)
            toast.error('An error occurred getting user roles');
            return null;
        }
    }

    loadUsers = async () => {
        try {
            let users = await agent.Users.list();
            return users;
        }
        catch (error) {
            console.log(error);
            toast.error('An error occurred getting users');
            return null;
        }
    }

    createCubeJsToken = async () => {
        try {
            let cubeJsToken: string = '';
            cubeJsToken = await agent.Account.createCubeJsToken();
            runInAction(() => {
                store.commonStore.setCubeJsToken(cubeJsToken);
            });
            return cubeJsToken;
        } catch (error) {
            console.log(error);
            return "";
        }
    }

    refreshToken = async () => {
        this.stopRefreshTokenTimer();
        try {
            const user = await agent.Account.refreshToken();
            runInAction(() => this.user = user);
            store.commonStore.setToken(user.token);
            this.startRefreshTokenTimer(user);
        } catch (error) {
            console.log(error);
        }
    }

    private startRefreshTokenTimer(user: User) {
        const jwtToken = JSON.parse(atob(user.token.split('.')[1]));
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(this.refreshToken, timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }

    setDisplayName = (name: string) => {
        if (this.user) this.user.displayName = name;
    }
}