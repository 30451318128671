import React from 'react'
import { format } from 'date-fns';
import { Button, Card, CardMeta, Grid, Icon, Image } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { Disbursement } from '../../app/models/disbursement';
import { observer } from 'mobx-react-lite';

const DisbursementListItem = ({ disbursement }: { disbursement: Disbursement }) => {
    const { spNameString, oblastString, contractSigningDate } = disbursement

    return (
        <Card fluid>
            <Card.Content>
                <Grid columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column>
                            <Image floated='left' size='mini' src={'/assets/m4h-logo-web-small.png'} />
                            <Card.Header>
                                {oblastString}
                            </Card.Header>
                            {contractSigningDate ? (
                                <Card.Meta>
                                    {spNameString} - {format(new Date(contractSigningDate), "dd MMM yyyy")}
                                </Card.Meta>
                            ) : (
                                <CardMeta>{spNameString}</CardMeta>)}
                        </Grid.Column>
                        <Grid.Column>
                            <Grid.Row>
                                <Button
                                    as={Link}
                                    to={`/disbursements/${disbursement.id}`}
                                    icon
                                    color='yellow'
                                    size='mini'
                                >
                                    <Icon name='eye' />
                                </Button>
                                <Button
                                    as={Link}
                                    to={`/updatedisbursements/${disbursement.id}`}
                                    icon
                                    color='teal'
                                    size='mini'
                                    title='Edit'
                                    data-tip='Edit'
                                >
                                    <Icon name='edit' />
                                </Button>
                            </Grid.Row>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Card.Content>
        </Card>
    )
}

export default observer(DisbursementListItem);
