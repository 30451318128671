import * as React from 'react';
import { Button, Radio, RadioGroup, FormControlLabel, FormControl, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, Grid } from '@mui/material/';
import { makeStyles } from "@material-ui/core";
import './HealthObservationStyle.css';
import { FormikProps } from 'formik';
import { SiteInspectionReportFormValues } from '../../app/models/siteinspectionreport';
import SirTextArea from '../../app/common/SirTextArea';
import { SirHealthSafetyObservation } from '../../app/models/sirhealthsafetyobservation';


interface HealthAndSafetyDialogBoxProps {
    formikProps: FormikProps<SiteInspectionReportFormValues>;
    healthAndSafetyIndex?: number; // Index of the healthAndSafety being edited, if applicable
    handleClose: () => void;
    open: boolean;
}

const HealthAndSafetyDialogBox: React.FC<HealthAndSafetyDialogBoxProps> = ({ formikProps, healthAndSafetyIndex, handleClose, open }) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            "& .MuiFormControl-root": {
                width: "90%",
                margin: theme.spacing(1),
            },
        },
    }));
    const classes = useStyles();

    const isCreatingNew = healthAndSafetyIndex === undefined;
    const fieldNamePrefix = isCreatingNew ? 'newSirHealthSafetyObservation' : `sirHealthSafetyObservations[${healthAndSafetyIndex}]`;

    const handleSave = () => {
        if (isCreatingNew) {
            const values: any = formikProps.values;
            // Logic to add a new activity to the qualityAndProgresses array
            formikProps.setFieldValue('sirHealthSafetyObservations', [...formikProps.values.sirHealthSafetyObservations!, values.newSirHealthSafetyObservation]);
        } else {
            let updatedSirHealthSafetyObservations = [...formikProps.values.sirHealthSafetyObservations!];
            updatedSirHealthSafetyObservations[healthAndSafetyIndex] = formikProps.values.sirHealthSafetyObservations![healthAndSafetyIndex];
            formikProps.setFieldValue('sirHealthSafetyObservations', updatedSirHealthSafetyObservations);
        }
        handleClose(); // Close the dialog box
    };

    const extendedValues = formikProps.values as SiteInspectionReportFormValues & { newSirHealthSafetyObservation?: SirHealthSafetyObservation };

    const radioValue = isCreatingNew
        ? extendedValues.newSirHealthSafetyObservation?.healthSafetyObservationId?.toString() || ''
        : extendedValues.sirHealthSafetyObservations![healthAndSafetyIndex]?.healthSafetyObservationId?.toString() || '';
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"  >
                    {isCreatingNew ? "Create New HSE" : "Update HSE"}
                </DialogTitle>
                <DialogContent>
                    <form className={classes.root}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel>Observation Type</FormLabel>
                                </FormControl>
                                <FormControl>
                                    <RadioGroup
                                        name={`${fieldNamePrefix}.healthSafetyObservationId`}
                                        value={radioValue}
                                        onChange={(e) => {
                                            const value = parseInt(e.target.value, 10);
                                            let valueText = "";
                                            if (value !== undefined && value === 1)
                                                valueText = "PPE"
                                            else if (value !== undefined && value === 2)
                                                valueText = "AUXILIARY INSTALL";
                                            else if (value !== undefined && value === 3)
                                                valueText = "DEEP EXC / HEIGHT PROT";
                                            else if (value !== undefined && value === 4)
                                                valueText = "SIGNAGE & MARKING";
                                            else if (value !== undefined && value === 5)
                                                valueText = "LAVATORIES";
                                            else if (value !== undefined && value === 6)
                                                valueText = "FIRST AID / HEALTH RISKS";
                                            else if (value !== undefined && value === 7)
                                                valueText = "Other";
                                            formikProps.setFieldValue(`${fieldNamePrefix}.healthSafetyObservationId`, value);
                                            formikProps.setFieldValue(`${fieldNamePrefix}.healthSafetyObservationText`, valueText)
                                        }}
                                    >
                                        <FormControlLabel value="1" control={<Radio />} label="PPE" />
                                        <FormControlLabel value="2" control={<Radio />} label="AUXILIARY INSTALL" />
                                        <FormControlLabel value="3" control={<Radio />} label="DEEP EXC / HEIGHT PROT" />
                                        <FormControlLabel value="4" control={<Radio />} label="SIGNAGE & MARKING" />
                                        <FormControlLabel value="5" control={<Radio />} label="LAVATORIES" />
                                        <FormControlLabel value="6" control={<Radio />} label="FIRST AID / HEALTH RISKS" />
                                        <FormControlLabel value="7" control={<Radio />} label="Other" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <SirTextArea
                                    name={`${fieldNamePrefix}.comment`}
                                    placeholder="Comments"
                                    minRows={2}
                                    maxRows={4}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" variant="outlined">Cancel</Button>
                    <Button onClick={() => handleSave()} color="primary" variant="contained">
                        {isCreatingNew ? 'Create' : 'Update'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default HealthAndSafetyDialogBox;