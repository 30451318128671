import React, { useState } from 'react';
import '@ant-design/compatible';
import { Layout } from 'antd';
import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import DashboardPageGC from './pages/DashboardPageGC';
import { useStore } from "../../app/stores/store";
import { useEffect } from 'react';
import LoadingComponent from '../layout/LoadingComponent';


export default function MainDashboard() {
    //let cubeJsToken = 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYmYiOjE2NzM1NDM3NjgsImV4cCI6MTY3MzU1NDU2OCwiaWF0IjoxNjczNTQzNzY4fQ.okMsFxUhUdtKawHYUOYmG3YQoxGwI57sIWaZlTx51Nb2EvR1sOoWXdeZtY7XEcS70nBf3FMb53qcLsE-6wvp_g';
    const [cubeJsToken, setCubeJsToken] = useState('');
    const [loadingInitial, setLoading] = useState(true);
    const API_URL = 'https://oms.usif.ua:444';
    //const API_URL = 'http://localhost:4000';
    const { userStore: { createCubeJsToken } } = useStore();

    useEffect(() => {
        getToken();
    }, []);

    const getToken = () => {
        createCubeJsToken().then((response) => {
            setCubeJsToken(response);
            setLoading(false);
        });
    };

    const cubejsApi = cubejs(cubeJsToken, {
        apiUrl: `${API_URL}/cubejs-api/v1`,
    });

    const AppLayout = ({ children }) => (
        <Layout
            style={{
                height: '100%',
            }}
        >
            <Layout.Content>{children}</Layout.Content>
        </Layout>
    );
    if (loadingInitial) return <LoadingComponent content='Loading dashboard...' />
    return (
        <CubeProvider cubejsApi={cubejsApi}>
            <AppLayout>{<DashboardPageGC />}</AppLayout>
        </CubeProvider>
    )
};
