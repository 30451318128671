import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { FormField, Grid, Header, Segment, Select } from 'semantic-ui-react';
import { Oblast } from '../../../app/models/oblast';
import { useStore } from '../../../app/stores/store';
import SirReportsDashboard from './DashboardComponents/SirReportsDashboard';
import SubProjectsDashboard from './DashboardComponents/SubProjectsDashboard';
import ReportDetails from './DashboardComponents/ReportDetails';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FundsDisbursedDashboard from './DashboardComponents/FundsDisbursedDashboard';
import MapDashboard from './DashboardComponents/MapDashboard';

interface Res {
    text: string;
    value: string;
}

export default observer(function DashboardPageGC() {
    const [getOblast, setOblast] = React.useState<Oblast[]>([]);
    const [getOblastName, setOblastName] = React.useState('');
    const [inspectionStartDate, setInspectionStartDate] = useState(
        new Date(new Date().setFullYear(new Date().getFullYear() - 10))
    );
    const [inspectionEndDate, setInspectionEndDate] = useState(new Date());
    const { refDataStore } = useStore();
    const [selectedSpNameSpCode, setSelectedSpNameSpCode] = useState<string>('');
    const [selectedSirId, setSelectedSirId] = useState<string>('');

    React.useEffect(() => {
        refDataStore
            .loadOblasts()
            .then((res) => setOblast(res ? res : []))
            .catch((error) => console.log(error));
    }, []);

    let results: Res[] = [];

    const mapOblastToResult = () => {
        getOblast.forEach((oblast) => {
            let r = {} as Res;
            r.text = oblast.name;
            r.value = oblast.name;
            results.push(r);
        });
    };
    let oblastName = '';

    mapOblastToResult();

    const onStartDateChange = (date) => {
        const newStartDate = new Date(date);
        newStartDate.setHours(newStartDate.getHours() + 12);
        setInspectionStartDate(newStartDate);
    };
    const onEndDateChange = (date) => {
        const newEndDate = new Date(date);
        newEndDate.setHours(newEndDate.getHours() + 12);
        setInspectionEndDate(newEndDate);
    };

    return (
        <div style={{ paddingBottom: '260px' }}>
            <Segment
                clearing
                style={{
                    minHeight: `calc(100vh - 260px)`,
                    border: "0px",
                }}
            >
                <Grid columns={4} stackable>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Segment>
                                <Header as="h2">USIF Construction Monitoring Dashboard</Header>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <Header as="h4">Filter by Disbursement Date</Header>
                            <FormField style={{ display: 'flex' }} key='inspectionFilterDate'>
                                <div style={{ width: '180px', marginRight: '10px' }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            disableFuture
                                            openTo="year"
                                            views={['year', 'month', 'day']}
                                            label="Start Date"
                                            value={inspectionStartDate}
                                            onChange={(date) => {
                                                onStartDateChange(date);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    sx={{
                                                        backgroundColor: '#EBF5DF',
                                                        boxShadow: '2px 2px 2px #888888',
                                                        '.MuiInputBase-input': { marginRight: 5 },
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div style={{ width: '180px', marginLeft: '10px', height: '38px' }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            disableFuture
                                            openTo="year"
                                            views={['year', 'month', 'day']}
                                            label="End Date"
                                            value={inspectionEndDate}
                                            onChange={(date) => onEndDateChange(date)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    sx={{
                                                        backgroundColor: '#EBF5DF',
                                                        boxShadow: '2px 2px 2px #888888',
                                                        '.MuiInputBase-input': { marginLeft: 2 },
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </FormField>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Header as="h4">Filter SP's by Oblast</Header>
                            <Select
                                style={{
                                    backgroundColor: '#EBF5DF',
                                    boxShadow: '2px 2px 2px #888888',
                                }}
                                options={results}
                                clearable
                                search
                                onChange={(e, data) => {
                                    oblastName = data.value as string;
                                    setOblastName(oblastName);
                                    setSelectedSpNameSpCode('');
                                }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <FormField key="FundsDisbursedDashboard">
                                <FundsDisbursedDashboard
                                    oblast={getOblastName}
                                    selectedSpNameSpCode={selectedSpNameSpCode}
                                    inspectionStartDate={inspectionStartDate}
                                    inspectionEndDate={inspectionEndDate}
                                />
                            </FormField>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <FormField key="MapDashboard">
                                <MapDashboard
                                    oblast={getOblastName}
                                    selectedSpNameSpCode={selectedSpNameSpCode}
                                />
                            </FormField>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <FormField key="SubProjectsDashboard">
                                <SubProjectsDashboard
                                    oblast={getOblastName}
                                    onSelect={setSelectedSpNameSpCode}
                                />
                            </FormField>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <FormField key="SirReportsDashboard">
                                <SirReportsDashboard
                                    oblast={getOblastName}
                                    selectedSpNameSpCode={selectedSpNameSpCode}
                                    onSelect={setSelectedSirId}
                                />
                            </FormField>
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <FormField key="ReportDetails">
                                <ReportDetails
                                    sirId={selectedSirId}
                                //onSelect={selectedSpNameSpCode}
                                />
                            </FormField>
                        </Grid.Column>

                    </Grid.Row>
                    {/*    <Grid.Row>*/}
                    {/*        <Grid.Column width={10}>*/}
                    {/*        </Grid.Column>*/}
                    {/*        <Grid.Column>*/}
                    {/*        </Grid.Column>*/}
                    {/*    </Grid.Row>*/}
                </Grid>
            </Segment>
        </div>
    );
});
