import * as React from 'react';
import { Grid, Button } from '@material-ui/core'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { v4 as uuid } from 'uuid';
import { toJS } from 'mobx'
import { SiteInspectionReportFormValues } from '../../app/models/siteinspectionreport';
import { useRef } from 'react';
import ObservanceDialogBox from './ObservanceDialogBox';
import { ObservanceOngoingActivity } from '../../app/models/observanceongoingactivity';
import { makeStyles } from "@material-ui/core/styles";
import { FormikProps } from 'formik';
import ConfirmationDialog from '../UI/ConfirmationDialog';

const useStyles = makeStyles({
    root: {
        background: "#278DAD",
        borderRadius: 3,
        border: 0,
        color: "white",
        height: 40,
        padding: "0 30px",
        boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
        '&:hover': {
            backgroundColor: '#D7C333',
            color: '#3c52b2'
        }
    },
    label: {
        textTransform: "capitalize"
    }
});

const arr: ObservanceOngoingActivity[] = [];

const ObservanceOnOngoingActivities = ({
    report,
    readOnly = false,
    formikProps = {} as FormikProps<SiteInspectionReportFormValues>}: {
        report: SiteInspectionReportFormValues,
        readOnly: boolean,
        formikProps: FormikProps<SiteInspectionReportFormValues>
    }) => {

    const classes = useStyles();

    const checkObs = () => {
        if (report.observanceOngoingActivities !== undefined && report.observanceOngoingActivities !== null) {
            report.observanceOngoingActivities = toJS(report.observanceOngoingActivities);
        } else {
            report.observanceOngoingActivities = arr;
        }
    }
    checkObs();
    let nextId = uuid();

    const [editingObservanceIndex, setEditingObservanceIndex] = React.useState<number | undefined>(undefined);

    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
    const [observanceToRemove, setObservanceToRemove] = React.useState(null);


    const initialValues: ObservanceOngoingActivity = {
        id: nextId,
        observance: "",
        photoName: "",
        photo: null,
        siteInspectionReportId: report.id
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const readOnlyColumnDefs = [
        { headerName: "ID", field: "id", hide: true },
        { headerName: "Observance", field: "observance", resizable: true },
    ]

    const editableColumnDefs = [
        { headerName: "ID", field: "id", hide: true },
        { headerName: "Observance", field: "observance", resizable: true },
        {
            headerName: "Actions",
            field: "id", resizable: true,
            cellRenderer: (params) =>
                <div>
                    <Button color="primary" onClick={() => onEdit(params.rowIndex)} ><ModeEditOutlineOutlinedIcon /></Button>
                    <Button color="secondary" onClick={() => onRemove(params.rowIndex)} ><DeleteOutlinedIcon /></Button>
                </div>
        }
    ]

    const gridRef = useRef<AgGridReact>(null);

    const defaultColDef = {
        sortable: true,
        flex: 1, filter: true,
    }

    const onEdit = (index: number) => {
        setEditingObservanceIndex(index);
        handleClickOpen();
    }

    const handleCreateActivity = () => {
        formikProps.setFieldValue('newObservanceOngoingActivity', initialValues);
        setEditingObservanceIndex(undefined);
        handleClickOpen();
    }

    const onRemove = (index) => {
        setObservanceToRemove(index);
        setIsConfirmDialogOpen(true);
    };

    const handleConfirmDelete = () => {
        const updatedObservanceOngoingActivities = formikProps.values.observanceOngoingActivities!.filter((_, i) => i !== observanceToRemove);
        formikProps.setFieldValue('observanceOngoingActivities', updatedObservanceOngoingActivities);

        setIsConfirmDialogOpen(false);
        setObservanceToRemove(null);
    };

    return (
        <div>
            {readOnly ? null : (
                <Grid container>
                    <Grid>
                        <Button
                            variant="contained"
                            onClick={() => { handleCreateActivity(); }}
                            classes={{
                                root: classes.root,
                                label: classes.label
                            }}
                        >
                            Add New Observance
                        </Button>
                    </Grid>
                </Grid>
            )}
            <div className="ag-theme-alpine" style={{ height: '300px' }}>
                <AgGridReact
                    ref={gridRef}
                    rowData={formikProps.values.observanceOngoingActivities}
                    columnDefs={readOnly ? readOnlyColumnDefs : editableColumnDefs}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    pagination={true}
                    rowSelection='multiple'
                    paginationPageSize={4}
                />
            </div>
            <ObservanceDialogBox
                formikProps={formikProps}
                observanceIndex={editingObservanceIndex}
                open={open}
                handleClose={handleClose}
            />
            <ConfirmationDialog
                open={isConfirmDialogOpen}
                onClose={() => setIsConfirmDialogOpen(false)}
                onConfirm={handleConfirmDelete}
            />
        </div>
    );
};

export default ObservanceOnOngoingActivities;

