import React, { useEffect, useState } from "react";
import { Query } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import { observer } from "mobx-react-lite";
import { Chart } from "react-google-charts";
import LoadingComponent from "../../../layout/LoadingComponent";

function ReportQualityProgressChartDetails({ sirId }: { sirId: string }) {
    const query: Query = {
        dimensions: [
            "VwSiteInspectionReportDetails.qpissuetype",
            "VwSiteInspectionReportDetails.qpid"
        ],
        filters: [
            {
                dimension: "VwSiteInspectionReportDetails.id",
                operator: "equals",
                values: [sirId ? sirId : "00000000-0000-0000-0000-000000000000"],
            },
            {
                dimension: "VwSiteInspectionReportDetails.qpid",
                operator: "set",
            },
        ],
    };

    const { resultSet, isLoading, error } = useCubeQuery(query);
    const [countData, setCountData] = useState<(string | number)[][]>([]);

    useEffect(() => {
        if (!isLoading && resultSet) {
            const counts: { [key: string]: number } = {}; // Use an explicit type annotation

            resultSet.tablePivot().forEach((row) => {
                const qpissuetype = row["VwSiteInspectionReportDetails.qpissuetype"] as string; // Type assertion
                counts[qpissuetype] = (counts[qpissuetype] || 0) + 1;
            });

            // Convert the counts object to an array suitable for the chart
            const dataArray = Object.entries(counts).map(([key, value]) => [key, value]);

            setCountData([["Issue Type", "Count"], ...dataArray]);
        }
    }, [resultSet, isLoading]);

    if (isLoading) {
        return <LoadingComponent content="Loading..." />;
    }

    // Check for errors or missing sirId
    if (!sirId || error) {
        return (
            <div>
                <div>
                    <b></b>
                </div>
                <div>
                </div>

            </div>
        );
    }

    if (countData.length === 0) {
        return <div>No data available.</div>;
    }

    const options = {
        title: "Quality Progress Issues",
        colorAxis: {
            maxValue: 500,
        },
        legend: "none",
        hAxis: {
            title: "Count",
        },
        vAxis: {
            title: "Issue Type",
        },
        colors: ["#ffaa01"],
   };

    return (
        <Chart
            chartType="BarChart"
            width={"100%"}
            height={"200px"}
            loader={<div>Loading Chart</div>}
            data={countData}
            options={options}
        />
    );
}

export default observer(ReportQualityProgressChartDetails);
