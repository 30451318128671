import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller';
import { Grid, Loader } from 'semantic-ui-react'
import { PagingParams } from '../../app/models/pagination';
import { useStore } from '../../app/stores/store';
import ReportListItemPlaceholder from './ReportListItemPlaceHolder';
import SiteInspectionReportFilters from './SiteInspectionReportFilters';
import SiteInspectionReportList from './SiteInspectionReportList';

export default observer(function SiteInspectionReportDashboard() {
    const { siteInspectionReportStore } = useStore();
    const { loadSiteInspectionReports, siteInspectionReportRegistry, setPagingParams, pagination } = siteInspectionReportStore;
    const [loadingNext, setLoadingNext] = useState(false);

    function handleGetNext() {
        setLoadingNext(true);
        setPagingParams(new PagingParams(pagination!.currentPage + 1))
        loadSiteInspectionReports().then(() => setLoadingNext(false));
    }

    useEffect(() => {
        if (siteInspectionReportRegistry.size <= 1) loadSiteInspectionReports();
    }, [siteInspectionReportRegistry.size, loadSiteInspectionReports])


    return (
        <Grid style={{ border: "none" }}>
            <Grid.Column width='10'>
                {siteInspectionReportStore.loadingInitial && !loadingNext ? (
                    <>
                        <ReportListItemPlaceholder />
                        <ReportListItemPlaceholder />
                        <ReportListItemPlaceholder />
                        <ReportListItemPlaceholder />
                    </>
                ) : (
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={handleGetNext}
                        hasMore={!loadingNext && !!pagination && pagination.currentPage < pagination.totalPages}
                        initialLoad={false}
                    >
                        <SiteInspectionReportList />
                    </InfiniteScroll>
                )}
            </Grid.Column>
            <Grid.Column width='6'>
                <SiteInspectionReportFilters />
            </Grid.Column>
            <Grid.Column width={10}>
                <Loader active={loadingNext} />
            </Grid.Column>
        </Grid>

    )
});