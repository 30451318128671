import React from "react";
import { Grid, Button } from '@material-ui/core';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import ProgresDialogBox from './ProgresDialogBox';
import { useRef } from 'react';



const ReadonlyDialogBox = () => {

  

    let [tableData, setTableData] = React.useState(null)
    const [formData, setFormData] = React.useState(null)
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const columnDefs = [
        { headerName: "ID", field: "id", hide: true },
        { headerName: "Comments", field: "comments", resizable: true },
        { headerName: "Percentage", field: "percentage", resizable: true },
        { headerName: "Photo", field: "photo", resizable: true },
        {
            headerName: "Actions", field: "id", resizable: true, cellRenderer: (params) => <div>
                <Button color="primary" ><ModeEditOutlineOutlinedIcon /></Button>
                <Button color="secondary" ><DeleteOutlinedIcon /></Button>
            </div>
        }
    ]

    const gridRef = useRef<AgGridReact>(null);


    const defaultColDef = {
        sortable: true,
        flex: 1, filter: true,
        scrollbars: true
    }


    return (
        <div>
            <Grid container>
                <Grid>
                    <Button variant="contained" color="primary" onClick={() => {
                        handleClickOpen();
                    }} >Add Progress</Button>
                </Grid>
            </Grid>
            <div className="ag-theme-alpine" style={{ height: '300px' }}>
                <AgGridReact
                    ref={gridRef}
                    //rowData={init}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    pagination={true}
                    rowSelection='multiple'
                    paginationPageSize={4}
                />
            </div>
            <ProgresDialogBox
                open={open}
                handleClose={handleClose}
                data={formData}
               // handleFormSubmit={handleFormSubmit}
            />
        </div>
    );
}

export default ReadonlyDialogBox;